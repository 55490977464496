import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { withTranslation } from "react-i18next";
import { RiShoppingBasket2Fill } from "react-icons/ri";

import styles from "./styles.module.scss";

const CartBox = ({ t, visibleCart, isVisibleCart, count }) => {
  return (
    <>
      <div
        className={styles.mobile}
        onClick={() => isVisibleCart(!visibleCart)}
      >
        {count > 0 && (
          <div className={styles.countLittle}>
            <span>{count}</span>
          </div>
        )}
        <RiShoppingBasket2Fill className={styles.cartIcon} />
      </div>

      <div className={styles.desktop}>
        <button
          className={styles.button}
          onClick={() => isVisibleCart(!visibleCart)}
        >
          {t("components.cart.button")}
          <span className={styles.divider}>|</span>
          <span className={styles.arrowCount}>
            <FaArrowRight className={styles.arrow} />
            <span className={styles.count}>{count}</span>
          </span>
        </button>
      </div>
    </>
  );
};

export default withTranslation()(CartBox);
