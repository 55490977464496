import API from './base/api';
import {SERVER} from '../constants/config';
import {currentLang} from '../helpers/language';

class ArticlesApi extends API {
  async getArticles() {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/articles?_locale=${lang}`,
    });
  }

  async getArticle(id) {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/articles/${id}?_locale=${lang}`,
    });
  }
}

export default function articlesApi(request) {
  return new ArticlesApi(request);
}
