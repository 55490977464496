import API from './base/api';
import {SERVER} from '../constants/config';
import {currentLang} from '../helpers/language';

class CategoriesApi extends API {
  async getCategories() {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/categories?_locale=${lang}`,
    });
  }

  async getCategory(id) {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/categories/${id}?_locale=${lang}`,
    });
  }
}

export default function catigoriesApi(request) {
  return new CategoriesApi(request);
}
