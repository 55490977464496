import React from "react";

import assets from "../../assets";

import styles from "./styles.module.scss";

const Icons = ({ data }) => {
  if (data.icon.vegetarian) {
    return (
      <span>
        <img className={styles.icon} src={assets.ICONS.PLANT} />
      </span>
    );
  } else if (data.icon.spicy) {
    return (
      <span>
        <img className={styles.icon} src={assets.ICONS.PEPPER} />
      </span>
    );
  } else if (data.icon.very_spicy) {
    return (
      <span>
        <img className={styles.icon} src={assets.ICONS.PEPPER} />
        <img className={styles.icon} src={assets.ICONS.PEPPER} />
      </span>
    );
  } else {
    return null;
  }
};

export default Icons;
