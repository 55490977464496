export default {
  translation: {
    name: "UA",
    currency: " ₴",
    charSet: "utf-8",
    status:
      "Ми тимчасово не приймаємо замовлення. Приносимо вам свої вибачення",
    seo: {
      about: {
        title: "🍕 Сімейна піцерія | Bella Mozzarella",
        keywords: "",
        description:
          "Сімейна піцерія Bella Mozzarella – це найсмачніша італійська піца у Харкові. Наша адреса: Харків, вулиця Метробудівників 17-А. Приходь і переконайся.",
      },
      bonuses: {
        title: "Бонусна програма | Bella Mozzarella",
        keywords: "",
        description:
          "Бонусна програма Bella Mozzarella. Цілодобова доставка піци у Харкові.",
      },
      delivery: {
        title:
          "Доставка їжі Харків цілодобово додому чи офісу | Bella Mozzarella",
        keywords:
          "доставка їжі, доставка їжі харків, цілодобова доставка їжі, цілодобова доставка їжі харків, цілодобова доставка їжі у харкові, доставка їжі у харкові",
        description:
          "Доставка їжі Харків цілодобово. Виконуємо доставку їжі додому в Харкові 24/7. Безкоштовна доставка їжі при замовленні від 249 грн. Дзвони – ☎ 098-43-21-000!",
      },
      terms: {
        title: "Угода користувача | Bella Mozzarella",
        keywords: "",
        description: "",
      },
      main: {
        title:
          "Піца Харків | Доставка піци Харків цілодобово | Bella Mozzarella",
        keywords:
          "піца харків, доставка піци харків, замовити піцу харків, доставка їжі, доставка їжі харків, замовити піцу, доставка піци, цілодобова доставка їжі, цілодобова доставка їжі Харків",
        description:
          "Піца Харків. Доставка піци Харків цілодобово на будинок або офіс. ✅️ Замовити піцу Харків з доставкою за телефоном ☎ +38 (098) 43-21-000. ❗ Постійні акції та бонусна програма. Європейська якість та сервіс за доступними цінами! Доставка піци до 59 хвилин. Доставка їжі Харків – це смачно!",
        page: "https://bellamozzarella.com.ua/uk/",
      },
      half: {
        title: "🍕 Піца з половинок у Харькові | Bella Mozzarella",
        keywords: "",
        description:
          "Піца від 119 гривень. Швидка та безкоштовна доставка піци додому та в офіс.",
      },
    },
    components: {
      apps: {
        title: "Bella Mozzarella",
        text: "Завантажити додаток",
      },
      header: {
        bonus: "Бонусна програма",
        delivery: "Доставка та оплата",
        city: "Харкiв",
      },
      badge: {
        new: "NEW",
      },
      navigation: {
        pizzas: "Піца",
        combos: "Комбо",
        snacks: "Закуски",
        desserts: "Десерти",
        drinks: "Напої",
        other: "Інше",
        promotions: "Акції",
        contacts: "Контакти",
        about: "Про нас",
      },
      category: {
        new: "Новинка",
      },
      nutritional: {
        nutriValue: "Харчова цінність на 100 г",
        energy: "Енерг. цінність",
        squirrels: "Білки",
        fats: "Жири",
        carbohydrates: "Вуглеводи",
        weight: "Вага ",
        gram: "г",
        calories: "ккал",
      },
      cart: {
        button: "Кошик",
      },
      footer: {
        joinTheTeam: "Приєднатися до команди Bella Mozzarella",
        joinTheTeamButton: "Заповнити анкету",
        title1: "Інформація",
        f1text1: "Акції",
        f1text2: "Бонусна програма",
        f1text3: "Доставка та оплата",
        title2: "Контакти",
        f2text1: "+38 (093) 43 21 000",
        f2text2: "+38 (098) 43 21 000",
        f2text3: "+38 (099) 43 21 000",
        f2text4: "bellamozzarella.com.ua@gmail.com",
        title3: "Самовивіз",
        f3text1: "Сімейна піцерія",
        f3text2: "Угода користувача",
        f3text3: "Харкiв, Центр, м. Університет",
        f3text4: "вулиця Сумська, 73",
        f3text5: "Час роботи: 09:00 - 22:00",
        f3text6: "Проспект Петра Григоренка, 18/1",
        f3text7: "Час роботи: 09:00 - 22:00",
        copyright1: "Bella Mozzarella",
        copyright2: "© 2020 - 2024",
      },
      purchase: {
        text: "Товар доданий до кошика",
      },
    },
    pages: {
      main: {
        components: {
          categories: {
            new: "Новинка",
            select: "Вибрати",
            add: "Вибрати",
            title: "Піца з половинок",
            description: "Зберіть свою піцу з двома різними смаками",
            craft: "Збирати",
          },
          combo: {
            title: "Комбо",
            subtitle: "Комбінуй",
          },
          stories: {
            link: "Замовити",
          },
          info: {
            title: "Піца Харків.",
            subTitle1: "Доставка піци Харків.",
            subTitle2: "Замовити піцу Харків.",
            subTitle3: "Піца із сирними бортиками Харків.",
            subTitle4: "Доставка їжі Харків.",
            textBolt1: "Переваги нашої доставки піци:",
            textBolt2: "Наші телефони доставки:",
            text1:
              "Піца Харків порадує Вас великою кількістю начинки та величезним шаром сиру зверху. У сучасному світі з шаленим ритмом життя доставка піци в Харкові стала користуватися величезною популярністю. У сімейній піцерії Bella Mozzarella працює цілодобова доставка піци у Харкові. Цілодобова доставка піци у Харкові працює цілий рік, у будь-який час дня та ночі, у будь-яку погоду. Ми доставимо Вашу улюблену піцу до будь-якої точки міста. Ви отримаєте готову, обрану на свій смак страву швидко, протягом 59 хвилин. Наші піци готуються спеціально навченими кухарями, тільки зі свіжих продуктів, використовуючи старовинні рецепти та новітні технології приготування.",
            text2:
              "Доставка піци Харків від Bella Mozzarella пропонує понад 15 видів піци. Ви можете обрати піцу:",
            text3:
              "Доставка у Харкові здійснюється нашими кур'єрами спеціальними термосумками, що зберігають тепло приготовленої піци. Ви зможете завжди насолодитися Вашою улюбленою гарячою піцею.",
            text4:
              "Замовити піцу Харків дуже просто, швидко, зручно. Наша піца готується на дуже тоненькому тісті, вона настільки смачна, що з'їдається навіть краєчок цього витвору. Готує її наш шеф-кухар зі свіжих, щойно куплених продуктів, ретельно відібраних. Замовивши піцу в Харкові Bella Mozzarella, Ви більше не захочете розмінюватися на менше. Для цього не потрібно мати гарну пам'ять, запам'ятовуючи багато різних номерів, не потрібно бути крутим програмістом, при замовленні страв на різний смак. Потрібно просто зателефонувати оператору або оформити замовлення на сайті всього кілька кліків. Цілодобова доставка їжі у Харкові від Bella Mozzarella приємно здивує Вас. Ми чекаємо на Вас. Кожен клієнт для нас унікальний та важливий. Ми завжди раді Вам. Замовивши цілодобову доставку їжі в Харкові в Bella Mozzarella, Ви отримаєте улюблену, високоякісну страву.",
            text5:
              "Зовсім недавно ми почали тішити своїх гостей новими піцами, додавши в меню можливість замовити піцу із сирним бортиком. Піца з сирним бортиком вершкового сиру Філадельфія надає піці більше ніжності. Також у нас є борт з вершковим сиром Філадельфія та шинкою. При замовленні піци з будь-яким бортиком – наш кур'єр привезе Вам восьмикутну піцу. Восьмикутна піца від Bella Mozzarella – перша піца в Харкові виконана в такому унікальному дизайні, яка ми впевнені припаде Вам до смаку.",
            text6:
              "Bella Mozzarella – це не тільки служба доставки їжі в Харкові, а й сімейна піцерія. Bella Mozzarella - місце, де відпочиває душа та тіло. Місце, де можна відпочити самому, з сім'єю, з друзями, з колегами. Тут на Вас чекають страви італійської кухні, які нікого не залишать байдужим. У нас можна посидіти не лише усередині ресторану, а й на літньому майданчику. Найбільшою популярністю користується піца. Це блюдо, що полюбилися місцевим жителям, щільно узвичаїлося. Жоден корпоратив, свято не обходиться без піци. У Bella Mozzarella можна не лише посидіти та відпочити у самому закладі, можна організувати корпоратив, відзначити будь-яке свято. Цілодобова доставка піци у Харкові, працює швидко, надійно та якісно, таким чином, зможете отримати не тільки смакову насолоду, але й споглядати первозданну красу самої страви. Bella Mozzarella - не просто сімейна піцерія, це місце, де завжди Вам раді і завжди Вас чекають. Вас зустріне ввічливий, привітний персонал, затишна атмосфера, смачна їжа, якісне обслуговування та повний релакс.",
            text7:
              "Доставка їжі Харків від Bella Mozzarella забезпечить вам комфорт, радість, відчуття свята, порадує Вас швидкою доставкою, що дозволить Вам завжди бути в хорошій формі та чудовому настрої.",
            pizzaList1: "з курячим філе;",
            pizzaList2: "з грибами та курячим філе;",
            pizzaList3: "з телятиною;",
            pizzaList4: "з мисливськими ковбасками;",
            pizzaList5: "з шинкою;",
            pizzaList6: "з морепродуктами;",
            pizzaList7: "з різними видами сирів.",
            shippingBenefits1: "цілодобова доставка їжі у Харкові;",
            shippingBenefits2: "доставка їжі по Харкову - 59 хвилин;",
            shippingBenefits3:
              "зручна форма оплати (готівкова, за терміналом у кур'єра, Liqpay на сайті, безготівкова у мобільному додатку);",
            shippingBenefits4: "зручний мобільний додаток;",
            shippingBenefits5: "регулярні акції;",
            shippingBenefits6: "знижки на день народження.",
            phone1: "+38 (093) 4321000",
            phone2: "+38 (098) 4321000",
            phone3: "+38 (099) 4321000",
          },
        },
      },
      dish: {
        sizeSmall: "маленька",
        sizeMedium: "середня",
        liters: "мл",
        sizeBig: "велика",
        cm: "см",
        grams: " г",
        ingredientsDisplay: "Видалити інгредієнти",
        side: "Бортик",
        classicSide: "Класичний",
        cheeseSide: "Сирний",
        baconSide: "Сир та бекон",
        add: "Додати до піци",
        addToCart: "Додати до кошика",
      },
      combo: {
        change: "Змінити",
        board: "Бортик: ",
        choseCombo: "Вибрати комбо",
        addToCart: "Додати до кошика",
      },
      half: {
        choosePizzas: "Виберіть піци для лівої та правої половинки",
        addToCart: "Об'єднати половинки",
        choose: "Виберіть",
        title: "Виберіть піцу з половинок",
        left: "ліву половинку",
        right: "праву половинку",
        weight: "Вага ",
        gram: " г",
      },
      cart: {
        components: {
          list: {
            subTitle: "товару на",
            board: "Борт: ",
            additiveTitle: "Додати до замовлення?",
          },
          bonus: {
            name: "Дізнатися інформацію про мої бонуси",
            enterPhone: "Ввести телефон",
            title: "Використовувати бонуси",
            subTitle: "У вас є ",
            text1: "(Не більше ",
            text2: " % від замовлення)",
          },
          promo: {
            title: "Отримайте знижку",
            placeholder: "Ввести промокод",
            text1: "Промокод діє при мінімальному замовленні від ",
          },
          delivery: {
            title: "Доставка та оплата",
            home: "Доставка",
            cafe: "Самовивіз",
            delivery: "Доставка",
            pickup: "Самовивіз",
            name: "Ім'я*",
            phone: "Телефон*",
            address: "Адреса*",
            now: "Якнайшвидше",
            onTime: "На час",
            time: "Час",
            comment: "Коментар до замовлення",
            meals: "Кількість приладів",
            nomeals: "Не додавати прилади до замовлення",
            required: "*Ім'я, телефон адреса обов'язкова до заповнення",
            address1: "вулиця Сумська, 73 ",
            address2: "Проспект Петра Григоренко, 18/1 ",
          },
          payment: {
            method: "Оберіть спосіб оплати",
            paymentByCash: "Готівкою кур'єру",
            paymentByCart: "Через термінал",
            paymentByOnline: "Карткою онлайн",
          },
          liqpay: {
            goToPay: "Перейти до оплати",
          },
        },
        title: "Кошик",
        delivery: "Доставка",
        freeDelivery: "Безкоштовна доставка від ",
        discount: "Знижка: ",
        total: "Сума до сплати: ",
        payButton: "Сплатити ",
        checkoutButton: "Оформити замовлення на ",
        emptyText: "Ваш кошик порожній",
        doneTitle: "Успіх",
        doneText: "Ваше замовлення прийнято",
        doneText2: "Номер замовлення - ",
        donePaymentText:
          "Ваше замовлення прийнято! Тепер ви можете перейти до оплати.",
      },
      terms: {
        title: "Договір публічної оферти",
      },
    },
  },
};
