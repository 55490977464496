import React from "react";

import styles from "./styles.module.scss";

const Promo = ({ t, promo, setPromo, promoStart }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.name}>
          {t("pages.cart.components.promo.title")}
        </h3>
        <input
          className={styles.number}
          value={promo}
          onChange={(e) => setPromo(e.target.value)}
          placeholder={t("pages.cart.components.promo.placeholder")}
        />
        <p className={styles.text}>
          {t("pages.cart.components.promo.text1")}
          {promoStart}
          {t("currency")}
        </p>
      </div>
    </div>
  );
};

export default Promo;
