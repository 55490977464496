import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const Hint = ({ t }) => {
  const [isView, setIsView] = useState(false);

  const orders = useSelector((state) => state.orders.data, shallowEqual);

  useEffect(() => {
    if (orders.length > 0) {
      setIsView(true);
      setTimeout(() => setIsView(false), 3000);
    }
  }, [orders]);

  return (
    <div className={styles.container}>
      <div className={isView ? styles.blockActive : styles.block}>
        <p className={styles.text}>{t("components.purchase.text")}</p>
      </div>
    </div>
  );
};

export default withTranslation()(Hint);
