import React from "react";

import styles from "./styles.module.scss";

const Toggle = ({ items, activeItem, setActiveItem, activeIndex }) => {
  return (
    <ul className={styles.container}>
      {items.map((item, index) => {
        return (
          <li
            key={index}
            onClick={() => {
              let newArr = [...activeItem];
              newArr[activeIndex] = item;
              setActiveItem(newArr);
            }}
            className={
              item === activeItem[activeIndex]
                ? styles.activeBoard
                : styles.board
            }
          >
            {item}
          </li>
        );
      })}

      <div className={styles.hoverColor}></div>
    </ul>
  );
};

export default Toggle;
