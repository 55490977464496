import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '../../api';

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  async () => {
    const response = await api().settings.getSettings();
    return response.data;
  },
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {data: [], loading: false, error: null},
  reducers: {},
  extraReducers: {
    [getSettings.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getSettings.pending]: state => {
      state.loading = true;
    },
    [getSettings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default settingsSlice.reducer;
