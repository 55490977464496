import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '../../api';

export const getStories = createAsyncThunk('stories/getStories', async () => {
  const response = await api().stories.getStories();
  return response.data;
});

export const storiesSlice = createSlice({
  name: 'stories',
  initialState: {data: [], loading: false, error: null},
  reducers: {},
  extraReducers: {
    [getStories.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getStories.pending]: state => {
      state.loading = true;
    },
    [getStories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default storiesSlice.reducer;
