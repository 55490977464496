import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';

import {getCategories} from '../../store/redux/categories';
import {getCombos} from '../../store/redux/combos';
import {getStories} from '../../store/redux/stories';
import {getSettings} from '../../store/redux/settings';
import {getArticle} from '../../store/redux/article';
import ArticleView from './ArticleView';

const Article = ({t}) => {
  const pathname = window.location.pathname;
  const id = pathname.slice(pathname.lastIndexOf('/') + 1);
  const dispatch = useDispatch();
  const combos = useSelector(state => state.combos.data, shallowEqual);
  const stories = useSelector(state => state.stories.data, shallowEqual);
  const categories = useSelector(state => state.categories.data, shallowEqual);
  const article = useSelector(state => state.article.data, shallowEqual);

  const settings = useSelector(state => state.settings.data, shallowEqual);

  let categoriesWithCombos = [...categories];
  categoriesWithCombos = categoriesWithCombos.filter(category => {
    return category.visible && category;
  });

  categoriesWithCombos.splice(1, 0, {
    name: t('pages.main.components.combo.title'),
    position: 2,
    dishes: combos,
    visible: true,
  });

  useEffect(() => {
    dispatch(getArticle(id));
    dispatch(getStories());
    dispatch(getCombos());
    dispatch(getCategories());
    dispatch(getSettings());
  }, [t, dispatch]);

  if (!article) {
    return null;
  }

  return (
    <ArticleView
      article={article}
      stories={stories}
      categories={categoriesWithCombos}
      allCategories={categories}
      settings={settings}
      path={'category'}
    />
  );
};

export default withTranslation()(Article);
