import React from "react";
import { IMaskInput } from "react-imask";

import styles from "./styles.module.scss";

const Bonus = ({ t, phone, getIikoBonuses, iiko, setBonus }) => {
  const phoneMask = "+{00} (000) 00 00 000";
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.name}>{t("pages.cart.components.bonus.name")}</h3>
        <IMaskInput
          className={styles.number}
          mask={phoneMask}
          value={phone}
          onAccept={(value) => getIikoBonuses(value)}
          placeholder={t("pages.cart.components.bonus.enterPhone")}
        />
      </div>
      {iiko !== null && phone.length === 19 && (
        <label className={styles.label}>
          <input
            className={styles.checkbox}
            name="isGoing"
            type="checkbox"
            onChange={(e) => setBonus(e.target.checked)}
          />
          <div className={styles.info}>
            <div className={styles.textBlock}>
              <p className={styles.title}>
                {t("pages.cart.components.bonus.title")}
              </p>
              <p className={styles.subTitle}>
                {t("pages.cart.components.bonus.subTitle")}
                {iiko}
                {t("currency")}
              </p>
              <p className={styles.text}>
                {t("pages.cart.components.bonus.text1")}
                50
                {t("pages.cart.components.bonus.text2")}
              </p>
            </div>
            <span className={styles.newChecknox}></span>
          </div>
        </label>
      )}
    </div>
  );
};

export default Bonus;
