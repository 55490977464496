import ARROW_RIGHT from "./icons/arrow_right.png";
import ARROW_DOWN from "./icons/arrow_down.svg";
import PEOPLE from "./icons/people.png";
import FACEBOOK from "./icons/facebook.png";
import INSTAGRAM from "./icons/instagram.png";
import YOUTUBE from "./icons/youtube.png";
import PEPPER from "./icons/pepper.png";
import PLANT from "./icons/plant.png";

import LOGO from "./images/logo.svg";
import LOGO_MINI from "./icons/icon.svg";
import LOGO_MINI2X from "./icons/icon.svg";
import LOGO_INVERT from "./images/logo-white.svg";
import GOOGLE_PLAY from "./images/googlePlay.png";
import APP_STORE from "./images/appStore.png";
import HALVES from "./images/halves.jpg";
import EMPTY_CART from "./images/emptyСart.png";
import DEFAULT_PIZZA from "./images/defaultPizza.png";

const ICONS = {
  ARROW_RIGHT,
  ARROW_DOWN,
  PEOPLE,
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  PEPPER,
  PLANT,
};

const IMAGES = {
  LOGO,
  LOGO_MINI,
  LOGO_MINI2X,
  LOGO_INVERT,
  GOOGLE_PLAY,
  APP_STORE,
  HALVES,
  EMPTY_CART,
  DEFAULT_PIZZA,
};

export default { ICONS, IMAGES };
