import React from "react";
import { withTranslation } from "react-i18next";

import { Seo, Navigation } from "../../components";
import { text } from "./data";

import styles from "./styles.module.scss";

const TermsView = ({ t }) => {
  return (
    <>
      <Seo t={t} path="terms" />
      <Navigation cart />
      <Navigation />
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{t("pages.terms.title")}</p>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(TermsView);
