import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";

import { Language } from "../index";
import assets from "../../assets";
import { currentLang } from "../../helpers/language";

import styles from "./styles.module.scss";

const Header = ({ t }) => {
  const [open, setOpen] = useState(false);
  const currentLanguage = currentLang();
  return (
    <>
      <div className={styles.mobile}>
        <div className={styles.city}>{t("components.header.city")}</div>
        <Link to={`/${currentLanguage}/`}>
          <img className={styles.logo} src={assets.IMAGES.LOGO} />
        </Link>
        <Language />
      </div>

      <div className={styles.desktop}>
        <div className={styles.block}>
          <div className={styles.phones}>
            <div className={open ? styles.collapseOpen : styles.collapseClose}>
              <div className={styles.city}>{t("components.header.city")}</div>
              <a className={styles.phone} href={"tel:+380934321000"}>
                <span className={styles.phoneIcon}>
                  <FaPhoneAlt />
                </span>
                (093) 43-21-000
              </a>
              <a className={styles.phone} href={"tel:+380984321000"}>
                <span className={styles.phoneIcon}>
                  <FaPhoneAlt />
                </span>
                (098) 43-21-000
              </a>
              <a className={styles.phone} href={"tel:+380994321000"}>
                <span className={styles.phoneIcon}>
                  <FaPhoneAlt />
                </span>
                (099) 43-21-000
              </a>
            </div>
            <div
              className={
                open ? styles.collapseControlOpen : styles.collapseControlClose
              }
            >
              {open ? (
                <FiChevronUp onClick={() => setOpen(false)} />
              ) : (
                <FiChevronDown onClick={() => setOpen(true)} />
              )}
            </div>
          </div>
          <Link to={`/${currentLanguage}/`}>
            <img className={styles.logo} src={assets.IMAGES.LOGO} />
          </Link>
          <Language />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Header);
