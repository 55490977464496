export const DEFAULT_LANG = "uk";

export const PIZZA_ID = 1;

export const FACEBOOK = "https://www.facebook.com/BellaMozzarella.com.ua";
export const INSTAGRAM = "https://www.instagram.com/bellamozzarella.kh/";
export const YOUTUBE =
  "https://www.youtube.com/channel/UCjpkxtXuTT4WhN4ZGkGonXg";

export const GOOGLE_PLAY =
  "https://play.google.com/store/apps/details?id=com.bellamozzarella&hl=ru&gl=US";
export const APP_STORE =
  "https://apps.apple.com/ua/app/bella-mozzarella-доставка-еды/id1532048319";

export const TEAM = "";

export const TYPE_DISHES = {
  dish: "dish",
  pizza: "pizza",
  combo: "combo",
  half: "half",
};

export const TYPE_DELIVERY = ["delivery", "pickup"];

export const MOBILE_WIDTH = 1024;
