import React from 'react';
import {withTranslation} from 'react-i18next';

import {currentLang} from '../../helpers/language';

import styles from './styles.module.scss';

const Description = ({i18n, text}) => {
  const lang = currentLang();
  const languages = Object.keys(i18n.options.resources);

  if (text) {
    return (
      <div className={styles.description}>
        <div dangerouslySetInnerHTML={{__html: text}} />
      </div>
    );
  }

  if (lang === languages[0]) {
    return (
      <div className={styles.description}>
        <h1>Pizza in Kharkov.</h1> <br />
        <p>
          This Pizza in Kharkov will delight you with a large amount of toppings
          and a huge layer of cheese on the top. In the modern world with a
          crazy rhythm of life, pizza delivery in Kharkov has become very
          popular. The family pizzeria Bella Mozzarella offers 24-hours pizza
          delivery in Kharkov. This 24-hours pizza delivery in Kharkov works all
          year round, at any time of the day or night, in any weather. We will
          deliver your favorite pizza anywhere in the city. You will receive a
          prepared dish, chosen to your taste, quickly, during 59 minutes. Our
          pizzas are prepared by specially trained chefs and we use only fresh
          ingredients, old recipes and the latest cooking technologies.
        </p>
        <br />
        <h2>Pizza delivery in Kharkov.</h2> <br />
        <p>
          Pizza delivery in Kharkov from Bella Mozzarella offers more than 15
          types of pizza. You can choose pizza:
          <br />
          - with chicken fillet; <br />
          - with mushrooms and chicken fillet;
          <br />
          - with veal;
          <br />
          - with hunting sausages;
          <br />
          - with ham;
          <br />
          - with seafood;
          <br />
          - with different types of cheeses.
          <br />
          Delivery in Kharkov is carried out by our couriers with special
          thermal bags that keep the heat of the cooked pizza. You can always
          enjoy your favorite hot pizza.
        </p>
        <br />
        <h2>How to order pizza in Kharkov?</h2>
        <br />
        <p>
          Ordering pizza Kharkov is very simple, fast and convenient. Our pizza
          is prepared on very thin dough; it is so tasty that even a piece of
          this creation is eaten! It is prepared by our chef from fresh, newly
          purchased products, which are carefully selected. Having ordered pizza
          in Kharkov at Bella Mozzarella, you will no longer want to exchange
          for less. To do this, you do not need to have a good memory,
          remembering many different numbers and you do not need to be a cool
          programmer to order dishes for different tastes. You just need to call
          the operator or place an order on the site in just a couple of clicks.
          24-hours food delivery in Kharkov from Bella Mozzarella will
          pleasantly surprise you. We are waiting for you. Each client is unique
          and important to us. We are always glad to see you. By ordering
          24-hours food delivery in Kharkov at Bella Mozzarella, you will
          receive your favorite, exceptionally high-quality dish.
        </p>
        <br />
        <h3>Pizza with cheese side in Kharkov.</h3> <br />
        <p>
          More recently, we have begun to delight our guests with new pizzas,
          adding the ability to order pizza with a cheese board to the menu.
          Philadelphia cream cheese topping in board of the pizza adds more
          tenderness to this dish. We also have a board with Philadelphia cream
          cheese and ham. When you order pizza with any of the boards - our
          courier will bring you an octagonal pizza. Octagonal pizza from Bella
          Mozzarella is the first pizza in Kharkov made in such a unique design,
          which we are sure you will like.
        </p>
        <br />
        <h3>Advantages of our pizza delivery:</h3> <br />
        <ol>
          <li>1. 24-hours food delivery in Kharkov; </li>
          <li>2. food delivery in Kharkov - 59 minutes;</li>
          <li>
            3. convenient form of payment (cash, by terminal at the courier,
            Liqpay on the website, non-cash in the mobile application);
          </li>
          <li> 4. convenient mobile application; </li>
          <li>5. regular promotions; </li>
          <li>6. Birthday discounts.</li>
        </ol>
        <p>
          Bella Mozzarella is not only a food delivery service in Kharkov, but
          also a family pizzeria. Bella Mozzarella is a place where your soul
          and body rest. The place, where you can relax alone, with family, with
          friends or with colleagues. Here you will find Italian dishes that
          will not leave anyone indifferent. With us you can sit not only inside
          the restaurant, but also on the summer terrace. Pizza is the most
          popular here. This dish, beloved by the locals, has come into common
          use. No one corporate event is complete without pizza. In Bella
          Mozzarella you can not only sit and relax in the institution itself,
          but also you can organize a corporate party or celebrate absolutely
          any holiday. 24-hours pizza delivery in Kharkov works quickly,
          reliably and efficiently, so you can get not only taste pleasure, but
          also contemplate the pristine beauty of the dish itself. Bella
          Mozzarella is not just a family pizzeria, it is the place where you
          are always welcome and we are always waiting for you. You will be met
          by polite, hospitable staff, cozy atmosphere, delicious food, quality
          service and complete relaxation.
        </p>
        <br />
        <h3>Food delivery in Kharkov.</h3> <br />
        <p>
          Food delivery in Kharkov from Bella Mozzarella will provide you with
          comfort, joy, a sense of celebration, will delight you with fast
          delivery, which will allow you to always be in good shape and in a
          good mood.
        </p>
        <br />
        <p>Our delivery phone numbers:</p> <br />
        <ul>
          <li>+38 (093) 4321000</li>
          <li>+38 (098) 4321000</li>
          <li>+38 (099) 4321000</li>
        </ul>
      </div>
    );
  } else if (lang === languages[1]) {
    return (
      <div className={styles.description}>
        <h1>Bellamozzarella: Ваш комфортний шлях до насолоди смаком</h1> <br />
        <p>
          Сучасний ритм життя вимагає ефективних рішень, і саме тому можливість
          отримати готові страви без зайвого клопоту стає важливим аспектом.
          Обираючи Bellamozzarella, ви насолоджуєтесь автентичною кухнею в
          будь-який момент – вдома чи на роботі. Ми пропонуємо великий вибір
          страв, які підходять для різних випадків та смаків.
        </p>
        <br />
        <h2>Свежість та натуральність – основа нашої філософії</h2> <br />
        <p>
          Всі страви готуються з використанням тільки свіжих і якісних
          інгредієнтів, які зберігають свій смак та корисні властивості.
          Продукти обираються з ретельністю, що гарантує високу якість кожної
          страви, яку ви отримуєте. Ми прагнемо, щоб кожна страва приносила
          справжню насолоду.
        </p>
        <br />
        <h2>Ідеально підібране меню для кожної події</h2> <br />
        <p>
          Bellamozzarella пропонує широкий вибір блюд, що підходять для
          будь-яких потреб. Проводите ділову зустріч? Влаштовуєте вечерю вдома?
          Наше меню включає варіанти для всіх випадків: від легких перекусів до
          ситних основних страв. Ви завжди знайдете, що підходить саме вам.
        </p>
        <br />
        <h2>Ситні страви для повноцінного обіду</h2> <br />
        <p>
          Якщо ви шукаєте поживні варіанти для обіду або вечері, наш асортимент
          м’ясних та рибних блюд вразить вас різноманітністю. Страви з курятини,
          свинини або яловичини ідеально доповнені гарнірами, що створюють
          збалансоване харчування, яке дозволить вам відчути справжню насолоду
          від їжі.
        </p>
        <br />
        <h2>Швидкі та легкі перекуси для активного дня</h2> <br />
        <p>
          Іноді потрібен лише швидкий та легкий перекус, і для цього у нас є
          широкий вибір салатів та сендвічів. Вони ідеально підходять для
          швидкої перерви під час роботи або для легкого обіду. Завдяки
          різноманіттю меню ви зможете підтримувати енергію на високому рівні
          протягом всього дня.
        </p>
        <br />
        <h2>Різноманіття для кожного смаку</h2> <br />
        <p>
          Меню Bellamozzarella об єднує кращі страви української та міжнародної
          кухні. Для кожного знайдеться улюблена їжа, незалежно від того, чи в
          іддаєте ви перевагу традиційним смакам чи хочете спробувати щось нове.
          Це дозволяє легко обрати те, що вам до душі, і насолоджуватися не
          тільки смаком, але й комфортом обслуговування.
        </p>
        <br />
        <h1>Піца Харків.</h1> <br />
        <p>
          Піца Харків порадує Вас великою кількістю начинки та величезним шаром
          сиру зверху. У сучасному світі з шаленим ритмом життя доставка піци в
          Харкові стала користуватися величезною популярністю. У сімейній
          піцерії Bella Mozzarella працює цілодобова доставка піци у Харкові.
          Цілодобова доставка піци у Харкові працює цілий рік, у будь-який час
          дня та ночі, у будь-яку погоду. Ми доставимо Вашу улюблену піцу до
          будь-якої точки міста. Ви отримаєте готову, обрану на свій смак страву
          швидко, протягом 59 хвилин. Наші піци готуються спеціально навченими
          кухарями, тільки зі свіжих продуктів, використовуючи старовинні
          рецепти та новітні технології приготування.
        </p>
        <br />
        <h2>Доставка піци Харків.</h2> <br />
        <p>
          Доставка піци Харків від Bella Mozzarella пропонує понад 15 видів
          піци. Ви можете обрати піцу: <br />
          - з курячим філе; <br />
          - з грибами та курячим філе; <br />
          - з телятиною; <br />
          - з мисливськими ковбасками; <br />
          - з шинкою; <br />
          - з морепродуктами; <br />
          - з різними видами сирів.
          <br />
          Доставка у Харкові здійснюється нашими кур&apos;єрами спеціальними
          термосумками, що зберігають тепло приготовленої піци. Ви зможете
          завжди насолодитися Вашою улюбленою гарячою піцею.
        </p>
        <br />
        <h2>Замовити піцу Харків.</h2>
        <br />
        <p>
          Замовити піцу Харків дуже просто, швидко, зручно. Наша піца готується
          на дуже тоненькому тісті, вона настільки смачна, що з&apos;їдається
          навіть краєчок цього витвору. Готує її наш шеф-кухар зі свіжих, щойно
          куплених продуктів, ретельно відібраних. Замовивши піцу в Харкові
          Bella Mozzarella, Ви більше не захочете розмінюватися на менше. Для
          цього не потрібно мати гарну пам&apos;ять, запам&apos;ятовуючи багато
          різних номерів, не потрібно бути крутим програмістом, при замовленні
          страв на різний смак. Потрібно просто зателефонувати оператору або
          оформити замовлення на сайті всього кілька кліків. Цілодобова доставка
          їжі у Харкові від Bella Mozzarella приємно здивує Вас. Ми чекаємо на
          Вас. Кожен клієнт для нас унікальний та важливий. Ми завжди раді Вам.
          Замовивши цілодобову доставку їжі в Харкові в Bella Mozzarella, Ви
          отримаєте улюблену, високоякісну страву.
        </p>
        <br />
        <h3>Піца із сирними бортиками Харків.</h3> <br />
        <p>
          Зовсім нещодавно ми почали тішити своїх гостей новими піцами, додавши
          в меню можливість замовити піцу із сирним бортиком. Піца з сирним
          бортиком вершкового сиру Філадельфія надає піці більше ніжності. Також
          у нас є борт з вершковим сиром Філадельфія та шинкою. При замовленні
          піци з будь-яким бортиком – наш кур&apos;єр привезе Вам восьмикутну
          піцу. Восьмикутна піца від Bella Mozzarella – перша піца в Харкові
          виконана в такому унікальному дизайні, яка ми впевнені припаде Вам до
          смаку.
        </p>
        <br />
        <h3>Переваги нашої доставки піци:</h3> <br />
        <ol>
          <li>1. цілодобова доставка їжі у Харкові; </li>
          <li>2. доставка їжі по Харкову - 59 хвилин; </li>
          <li>
            3. зручна форма оплати (готівкова, за терміналом у кур&apos;єра,
            Liqpay на сайті, безготівкова у мобільному додатку);
          </li>
          <li>4. зручний мобільний додаток; </li>
          <li>5. регулярні акції; </li>
          <li>6. знижки на день народження. </li>
        </ol>
        <p>
          Bella Mozzarella – це не тільки служба доставки їжі в Харкові, а й
          сімейна піцерія. Bella Mozzarella - місце, де відпочиває душа та тіло.
          Місце, де можна відпочити самому, з сім&apos;єю, з друзями, з
          колегами. Тут на Вас чекають страви італійської кухні, які нікого не
          залишать байдужим. У нас можна посидіти не лише усередині ресторану, а
          й на літньому майданчику. Найбільшою популярністю користується піца.
          Це блюдо, що полюбилися місцевим жителям, щільно узвичаїлося. Жоден
          корпоратив, свято не обходиться без піци. У Bella Mozzarella можна не
          лише посидіти та відпочити у самому закладі, можна організувати
          корпоратив, відзначити будь-яке свято. Цілодобова доставка піци у
          Харкові, працює швидко, надійно та якісно, таким чином, зможете
          отримати не тільки смакову насолоду, але й споглядати первозданну
          красу самої страви. Bella Mozzarella - не просто сімейна піцерія, це
          місце, де завжди Вам раді і Вас завжди чекають. Вас зустріне
          ввічливий, привітний персонал, затишна атмосфера, смачна їжа, якісне
          обслуговування та повний релакс.
        </p>
        <br />
        <h2>Легкий Процес Замовлення</h2> <br />
        <p>
          Замовити їжу у нас дуже просто. Всього кілька кліків на нашому сайті –
          і ваша страва вже на шляху до вас. Ми пропонуємо зручний та інтуїтивно
          зрозумілий інтерфейс, щоб ви могли замовити їжу у Харкові без зайвих
          труднощів. Незалежно від того, чи замовляєте ви обід в офіс або вечерю
          додому, наша команда завжди працює швидко та надійно.
        </p>
        <br />
        <h2>Чому Обирають Нас? </h2> <br />
        <ol>
          <li>
            {' '}
            - Висока якість продуктів:Ми використовуємо лише свіжі та натуральні
            продукти для приготування кожної страви.{' '}
          </li>
          <li>
            {' '}
            - Швидка доставка: Ми гарантуємо, що ваша їжа буде доставлена
            вчасно, гарячою та готовою до вживання не залежно від погоди чи пори
            року.{' '}
          </li>
          <li>
            {' '}
            - Зручний процес замовлення: Легкий та швидкий процес замовлення на
            нашому сайті дозволяє вам зробити замовлення їжі у будь-який зручний
            час цілодобово.{' '}
          </li>
        </ol>
        <br />
        <h2>Замовляйте Їжу Прямо Зараз</h2> <br />
        <p>
          Не витрачайте час на приготування їжі, коли ви можете швидко замовити
          їжу у Харкові і отримати її гарячою та свіжою прямо до дверей. Ми
          пропонуємо доставку додому в Харкові або до офісу, щоб ви могли
          насолоджуватися смачними стравами в будь-який момент.
        </p>
        <br />
        <h2>
          Дставка їжі від Bellamozzarella – це ваш найкращий вибір для отримання
          смачної, поживної страви без зайвих турбот. Замовляйте прямо зараз, і
          ми доставимо вам їжу, приготовану зі свіжих інгредієнтів та з турботою
          про ваш комфорт!
        </h2>{' '}
        <br />
        <br />
        <h3>Доставка їжі Харків.</h3> <br />
        <p>
          Доставка їжі Харків від Bella Mozzarella забезпечить Вам комфорт,
          радість, відчуття свята, порадує Вас швидкою доставкою, що дозволить
          Вам завжди бути в хорошій формі та чудовому настрої.
        </p>
        <br />
        <p>Наші телефони доставки: </p> <br />
        <ul>
          <li>+38 (093) 4321000</li>
          <li>+38 (098) 4321000</li>
          <li>+38 (099) 4321000</li>
        </ul>
      </div>
    );
  } else if (lang === languages[2]) {
    return (
      <div className={styles.description}>
        <h1>Пицца Харьков.</h1> <br />
        <p>
          Пицца в Харькове пользуется повышенным спросом. Это блюдо, которое
          получило мировую известность. Побаловать себя им любят как дети, так и
          взрослые. Разнообразие пицц настолько велико, что иногда сложно
          определиться с выбором. Однако с полной уверенностью можно сказать,
          что каждый сможет подобрать блюдо, которое будет в полной мере
          соответствовать его гастрономическим предпочтениям. Если Вас
          интересует доставка пиццы на дом, Вам стоит обратиться к нам. Bella
          Mozzarella - это семейная пиццерия в Харькове. У нас вкуснейшая пицца,
          где много начинки. В этом убедились на собственном опыте многие
          харьковчане.
        </p>
        <br />
        <h2>Доставка пиццы Харьков.</h2> <br />
        <p>
          Сейчас доставка пиццы доступна для жителей разных стран мира и
          городов. Насладиться вкусным блюдом Вы можете как в семейной пиццерии,
          так и воспользоваться услугами доставки еды в Харькове. Bella
          Mozzarella предлагает пиццу с большим количеством начинки и обильным
          слоем сыра. Круглосуточная доставка пиццы – преимущество обращения в
          семейную пиццерию. Такую возможность предоставляют не все сервисы. Это
          позволяет насладиться любимым блюдом в любое время суток и в любые
          погодные условия. Для курьеров службы быстрой доставки пиццы не
          существует никаких преград. Bella Mozzarella ценит время своих
          клиентов. Пиццерия в Харькове доставит блюдо на протяжении 59 минут.
          Приготовлением пицц с большим количеством начинки занимаются опытные
          повара. Используются только свежие продукты. Изюминкой Bella
          Mozzarella можно назвать то, что повара пользуются старинными
          рецептами, но при этом используются новейшие технологии приготовления.
          Пицца получается невероятно вкусная и ароматная. Если Вы хотите купить
          пиццу в Харькове, обращайтесь к нам.
        </p>
        <br />
        <p>
          Bella Mozzarella предлагает широкое разнообразие блюд. Пицца с сырными
          бортиками сейчас на пике популярности. Это блюдо пришлось по душе
          большому количеству клиентов. Оно сейчас находится в топе продаж. Если
          Вы хотите купить пиццу, обратите внимание и на другие блюда. Если Вы
          любите простые, но вкусные яства, можете заказать пиццу с куриным
          филе. Хорошим вариантом является сочетание грибов и курицы. Многие
          люди, которых интересует круглосуточная доставка еды в Харькове,
          выбирают пиццу с телятиной или с охотничьими колбасками. Неповторимым
          вкусом отличается пицца с ветчиной или с морепродуктами. Мало кого
          оставляет равнодушным блюдо с различными видами сыров. Круглосуточная
          доставка еды – возможность получить вкуснейшую пиццу, где много
          начинки, в любое время дня и ночи. Bella Mozzarella заботится и о том,
          чтобы каждый клиент получил горячее блюдо. Курьеры перевозят еду в
          специальных термосумках, которые сохраняют тепло на протяжении
          продолжительного времени. Если Вы примите решение заказать пиццу в
          Харькове в Bella Mozzarella, то можете быть уверены, что получите
          горячее ароматное блюдо.
        </p>
        <br />
        <h2>Почему стоит заказать пиццу в Харькове в Bella Mozzarella?</h2>
        <br />
        <p>
          Круглосуточная доставка еды в Харькове от Bella Mozzarella пользуется
          большим спросом, поскольку здесь на первом месте вкусовые предпочтения
          и интересы клиента. Мы уже говорили о том, что повара готовят вкусные
          и сытные блюда. При приготовлении пиццы используются только свежие
          продукты. Отличительная черта – тоненькое тесто, но при этом много
          начинки. Заказать пиццу на дом в Харькове в Bella Mozzarella могут
          люди с разными финансовыми возможностями. Есть как доступные по цене,
          так и достаточно дорогие варианты, но в любом случае Вы получите
          вкусную еду. Повара в процесс ее приготовления вкладывают душу. По
          этой причине быстрая доставка пиццы в Харькове в Bella Mozzarella
          пользуется большим спросом.
          <br />
          Оформление заказа не вызывает больших сложностей. Если Вас интересует
          итальянская пицца в Харькове, заказать ее Вы можете посредством звонка
          оператору или в режиме онлайн на сайте. Процедура занимает минимальное
          количество времени.
        </p>
        <br />
        <h3>Пицца с сырными бортиками.</h3>
        <br />
        <p>
          Пицца с сырными бортиками в Харькове от Bella Mozzarella раз и
          навсегда покорит Ваше сердце. В качестве наполнителя может
          использоваться сливочный сыр «Филадельфия». Если любите что-то
          особенное, можете выбрать борт с сыром «Филадельфия» и ветчиной. Пицца
          на дом в Харькове будет доставлена без задержек.
        </p>
        <br />
        <h3>Преимущества доставки пиццы:</h3>
        <br />
        <p>
          Несколько слов хотелось бы сказать и о преимуществах доставки пиццы в
          Харькове Bella Mozzarella. В данном случае стоит отметить такие
          моменты:
        </p>
        <br />
        <ol>
          <li>
            1. возможность побаловать себя вкусным блюдом в любое время суток;
          </li>
          <li>2. выполнение доставки по Харькову на протяжении 59 минут;</li>
          <li>
            3. возможность оформления заказа с помощью мобильного приложения;
          </li>
          <li>4. получение скидки в день рождения; </li>
          <li>5. многочисленные акции. </li>
        </ol>
        <p>
          Быстрая доставка еды в Харькове выгодна еще и потому, что Вы можете
          оплатить заказ любым удобным для Вас способом. К таковым следует
          отнести:
        </p>
        <br />
        <ul>
          <li>наличные;</li>
          <li>терминал;</li>
          <li>
            безналичную оплату в мобильном приложении или на сайте Bella
            Mozzarella.
          </li>
        </ul>
        <br />
        <p>
          При желании Вы можете воспользоваться конструктором пицц. Это позволит
          получить блюдо, которое в полной мере соответствует Вашим
          гастрономическим потребностям.
        </p>
        <br />
        <p>
          Несколько слов скажем о том, по каким поводам стоит заказать пиццу в
          Харькове с доставкой. Многие люди живут в стремительном темпе жизни,
          поэтому на приготовление еды остается мало времени. Если Вы не
          успеваете приготовить ужин, в любой момент можете воспользоваться
          услугами доставки еды. Курьер привезет заказ в любое удобное Вам
          время, если вы заранее оформите заказ. Обращение в Bella Mozzarella
          избавит Вас от необходимости думать о том, что приготовить на ужин и
          как все успеть.
        </p>
        <br />
        <p>
          Услуги доставки пиццы с бортиками в Харькове полезны и для компаний
          друзей. Если Вы приняли решение устроить посиделки, посмотреть
          интересный фильм дома, то итальянское блюдо станет хорошим вариантом
          для перекуса. Вы сможете в полной мере насладиться общением с друзьями
          и вкусно поесть.
          <br />
          Если решили организовать детский день рождения, то подумайте о заказе
          пиццы. Сложно найти ребенка, который не любит это итальянское блюдо.
          При выборе начинки поинтересуйтесь, что любят дети. Тогда Вы точно
          сможете угодить каждому. Доставка пиццы актуальна и среди офисных
          сотрудников. Бывает так, что нет времени в обеденный перерыв сходить в
          столовую или кафе. В этом случае ситуацию спасет заказ пиццы в офис. А
          может Вам просто хочется чего-то особенного. Тогда Вас заинтересует
          пицца с морепродуктами в Харькове. Bella Mozzarella старается учесть
          вкусовые предпочтения клиентов. Широкое разнообразие пицц позволяет
          подобрать блюдо, которое действительно нравится. Bella Mozzarella –
          семейная пиццерия, которая прилагает максимальные усилия к тому, чтобы
          каждый клиент остался доволен как вкусом блюд, так и качеством
          предоставленного сервиса.
        </p>
      </div>
    );
  }
};

export default withTranslation()(Description);
