import React, { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { currentLang } from "../../helpers/language";

import styles from "./styles.module.scss";

const ShoppingCart = ({ visibleCart, isVisibleCart, children }) => {
  const lang = currentLang();
  const cartEl = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartEl.current && !cartEl.current.contains(event.target)) {
        isVisibleCart(!visibleCart);
        navigate(`../${lang}/`);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartEl, lang]);

  const closeSideBar = () => {
    isVisibleCart(!visibleCart);
    navigate(`../${lang}/`);
  };

  if (visibleCart) {
    return (
      <div className={visibleCart ? styles.container : styles.notVisible}>
        <div className={styles.close} onClick={closeSideBar}>
          <IoMdClose fontSize={45} className={styles.closeIcon} />
        </div>
        <div ref={cartEl} className={styles.content}>
          {children}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default withTranslation()(ShoppingCart);
