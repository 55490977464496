import React from "react";
import { withTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const Shutdown = ({ t, data }) => {
  const { work_site } = data;
  return (
    work_site &&
    !work_site && <div className={styles.notWork}>{t("status")}</div>
  );
};

export default withTranslation()(Shutdown);
