import API from './base/api';
import {SERVER} from '../constants/config';
import {currentLang} from '../helpers/language';

class StoriesApi extends API {
  async getStories() {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/stories?_locale=${lang}`,
    });
  }
}

export default function storiesApi(request) {
  return new StoriesApi(request);
}
