export const text = `
    <p>
        <b>1. Загальні положення.</b>
    </p>
    <p>
        Інтернет-магазин https://bellamozzarella.com.ua/ (далі — Продавець)
        публікує даний договір, що є публічним договором-офертою (далі —
        Договір) і має відповідну юридичну силу, про продаж товарів,
        представлених на офіційному інтернет-сайті Продавця, на адресу як
        фізичних, так і юридичних осіб (далі — Покупець).
    </p>
    <p>
        Відповідно до статей 633, 641, 642 Цивільного Кодексу України (далі
        — ЦКУ) та Правил продажу товарів на замовлення та поза торговельними
        або офісними приміщеннями, затверджених наказом Міністерства
        економіки України від 19.04.2007 №103, даний документ є офертою, а
        факт здійснення покупцем замовлення в усній (по телефону або в
        магазині, офісі) або письмовій (через сайт або електрону пошту)
        формі та отримання даного замовлення Продавцем є повним та
        безперечним прийняттям даного публічного договору та інформації,
        викладеної на сайті.
    </p>
    <p>
        Використання послуг та купівля товарів, що надаються
        інтернет-магазином https://bellamozzarella.com.ua/, передбачає згоду
        особи, що користується послугами або купує товар, з правилами,
        строками, обмеженнями та іншими умовами співпраці, викладеними в
        даному документі.
    </p>
    <p>
    <b>Приймаючи умови даного договору, покупець підтверджує, що:</b>
    </p>
    <p>А) на момент купівлі товару йому виповнилось 18 років;</p>
    <p>
        Б) інформація, що надається під час замовлення товарів або послуг, є
        правильною та актуальною;
    </p>
    <p>
        В) надана контактна інформація може бути використана з метою
        повідомлення про зміни в роботі, акційні пропозиції чи надання іншої
        інформації, пов’язаної з діяльністю продавця.
    </p>
    <p>
        На підставі вищезазначеного уважно ознайомтеся з даним договором
        (публічною офертою), і якщо Ви не згодні з будь-яким із пунктів
        оферти, будь ласка, залиште сайт.
    </p>
    <p>
        У даному Договорі, якщо зміст не потребує іншого, терміни слід
        тлумачити наступним чином:
    </p>
    <p>
        <b>«Оферта»</b> — публічна пропозиція Продавця, адресована будь-якій
        фізичній та юридичній особі з метою укласти договір куплі-продажу на
        існуючих умовах, зазначених у Договорі.
    </p>
    <p>
        <b>«Товар»</b>— перелік найменувань асортименту, представлений на
        офіційному сайті Продавця, але не обмежений тільки сайтом.
    </p>
    <p>
        <b>«Продавець»</b>— компанія, що реалізує Товар, представлений в
        інтернет-магазині, але не обмежений тільки сайтом.
    </p>
    <p>
        <b>«Покупець»</b>— фізична або юридична особа, що вступила в
        договірні відносини з Продавцем на умовах, зазначених у Договорі.
    </p>
    <p>
        <b>«Акцепт»</b>— повне та беззаперечне прийняття Покупцем умов
        Договору.
    </p>
    <p>
        <b>«Замовлення»</b>— окремі позиції з асортиментного переліку
        Товару, вказані Покупцем при розміщенні заявки на інтернет-сайті
        Продавця або замовлені будь-яким іншим способом.
    </p>
    <p>
        <b>«Кур’єрська доставка»</b>— безпосередня передача Товару від
        Продавця до Покупця незалежним підрядником, який надає послуги
        Продавцю відповідно до окремого укладеного договору. Продавець не
        здійснює самостійно доставку Товару.
    </p>
    <p>
        <b>«Інтернет-магазин»</b>— веб-сторінка Продавця
        https://bellamozzarella.com.ua/
    </p>
    <p>
        <b>«Місце продажу Товару»</b>— заклад громадського харчування
        (ресторан) Продавця.
    </p>
    <br />
    <p>
    <b>2. Предмет договору.</b>
    </p>
    <p>
        2.1. Продавець здійснює продаж Покупцю Товару відповідно до чинних
        на момент здійснення замовлення цін, а Покупець має право здійснити
        оплату і прийняти Товар відповідно до умов даного Договору.
    </p>
    <p>
        2.2. Даний договір є офіційним документом Продавця та невід’ємною
        частиною Оферти.
    </p>
    <p>2.3. Продаж Товару здійснюється в закладі Продавця.</p>
    <br />
    <p>
        <b>3. Момент укладання Договору.</b>
    </p>
    <p>
        3.1. Текст договору є публічною офертою (відповідно до статей 633,
        643 Цивільного Кодексу України та Правил продажу товарів на
        замовлення та поза торговельними або офісними приміщеннями,
        затверджених наказом Міністерства економіки України від 19.04.2007 №
        103).
    </p>
    <p>
        3.2. Факт оформлення замовлення в Продавця, як самостійно, так і за
        допомогою оператора, є беззаперечним прийняттям умов даного
        Договору, і Покупець надалі виступає як особа, що вступила в
        договірні відносини. Покупець зобов’язаний самостійно
        ознайомлюватися з умовами договору, продавець не зобов’язаний
        інформувати покупця про наявність договору.
    </p>
    <p>
        3.3. Моментом вступу Покупця в договірні відносини з Продавцем
        вважається момент замовлення Товару, незалежно від способу
        замовлення та форми оплати.
    </p>
    <p>
        3.4. На письмову вимогу Покупця Продавець оформлює договір з
        підписами сторін.
    </p>
    <p>
        3.5. Покупець самостійно та за власним бажання зв’язується з
        продавцем, що означає, що покупець ознайомився з даним договором та
        погоджується з ним.
    </p>
    <br />
    <p>
        <b>4. Ціна Товару.</b>
    </p>
    <p>
        4.1. Ціни в інтернет-магазині вказані в національній валюті України
        (гривня) за одиницю товару відповідно до існуючого прейскуранта.
    </p>
    <p>
        4.2. Продавець залишає за собою право змінювати ціну Товару до
        моменту оформлення замовлення без попередження Покупця.
    </p>
    <p>
        4.3. Остаточною є ціна, вказана менеджером під час оформлення
        замовлення.
    </p>
    <p>
        4.4. Ціни, вказані на сайті https://bellamozzarella.com.ua/, мають
        ознайомчий характер.
    </p>
    <br />
    <p>
        <b>5. Оплата Товару.</b>
    </p>
    <p>
        Покупець має можливість здійснити оплату Продавцю в готівковій або
        безготівковій формі (за допомогою платіжної картки Visa, Maestro,
        Mastercard або за допомогою веб-інтерфейсу LiqPay).
    </p>
    <p>
        5.1. При готівковій формі оплати Покупець зобов’язаний сплатити
        Продавцю ціну товару в момент його передачі, якщо інше не
        передбачено домовленістю або договором між сторонами.
    </p>
    <p>
        5.2. Оплата товарів та послуг, що надаються інтернет-магазином
        https://bellamozzarella.com.ua/, здійснюється в національній валюті
        України відповідно до цін і тарифів, встановлених на момент купівлі
        товарів або отримання послуг.
    </p>
    <p>
        5.3. Оплата товарів та послуг, що надаються інтернет-магазином
        https://bellamozzarella.com.ua/, здійснюється відповідно до розділу
        «Доставка та оплата», опублікованого на сайті
        https://bellamozzarella.com.ua/. Оплата зараховується тільки при
        умові дотримання порядку, опублікованого на сайті.
    </p>
    <p>
        5.4. У разі неоплати, неповної оплати або несвоєчасної оплати
        інтернет-магазин https://bellamozzarella.com.ua/ залишає за собою
        право ненадання товару, призупинення або повне невиконання взятих на
        себе зобов’язань і при цьому не несе відповідальності за можливі
        наслідки такого рішення / дій.
    </p>
    <p>
        5.5. Товари та послуги гарантуються і надаються тільки по факту
        отримання оплати інтернет-магазином https://bellamozzarella.com.ua/
        (за умови наявності товару на момент фіксування оплати Продавцем в
        імпортера, фізичної наявності товару на складі Продавця, можливості
        його доставки на склад Продавця або на адресу, зазначену Покупцем).
        До моменту отримання оплати інтернет-магазин
        https://bellamozzarella.com.ua/ не має ніяких зобов’язань перед
        Покупцем щодо товарів та послуг, замовлених Продавцем.
    </p>
    <p>
        5.6. При безготівковій формі оплати обов&apos;язок Покупця зі сплати
        вартості товару вважається виконаним з моменту зарахування
        відповідних грошових коштів на розрахунковий рахунок Продавця.
    </p>
    <p>
        5.7. При безготівковій формі розрахунку Покупець зобов’язаний
        пред’явити менеджеру або кур’єру документ, що підтверджує факт
        оплати Товару (платіжне доручення, квитанція про сплату тощо).
    </p>
    <p>
        5.8. Ціни на будь-які позиції Товару можуть бути змінені Продавцем в
        односторонньому порядку.У разі зміни ціни на замовлені позиції
        Товару до оплати Замовлення Продавець зобов’язаний у найкоротші
        строки повідомити Покупця про такі зміни. Покупець має право
        підтвердити або анулювати Замовлення. У разі відсутності зв’язку з
        Покупцем Замовлення вважається анульованим протягом 24-х годин з
        моменту зміни ціни на Товар.
    </p>
    <br />
    <p>
        <b>6. Доставка Товару.</b>
    </p>
    <p>
        6.1. Покупець має право отримати замовлений ним Товар за допомогою
        самостійного вивозу (самовивіз) із закладу громадського харчування
        (ресторану) Продавця або скористатися послугами кур’єрської
        доставки. Продавець самостійно не здійснює доставку Покупцю
        замовленого Товару.
    </p>
    <p>
        6.2. Самовивіз здійснюється Покупцем із закладу громадського
        харчування (ресторану) Продавця, зазначеного в замовленні.
    </p>
    <p>
        6.3. Кур’єрська доставка замовлення Покупцю здійснюється за
        погодженням із Покупцем і за вказаною ним адресою.
    </p>
    <br />
    <p>
        <b>7. Продаж товару та надання послуг.</b>
    </p>
    <p>
        7.1. Інтернет-магазин https://bellamozzarella.com.ua/ не гарантує
        безумовного виконання замовлення. Усі послуги та товари надаються за
        принципом «як є». Задіяні технічні системи (веб-сторінка та сам сайт
        https://bellamozzarella.com.ua/) можуть включати невиявлені технічні
        помилки, наслідком яких є неможливість виконання замовлення,
        придбання товару або несвоєчасне виконання замовлення. Крім того, до
        неможливості виконання замовлення або його несвоєчасного виконання
        можуть призвести обставини, зумовлені людським фактором, або ж
        обставини непереборної сили.
    </p>
    <p>
        7.2. У разі неможливості або несвоєчасного виконання взятих на себе
        зобов’язань за обставин, що викладені в п. 7.1 (виключаючи
        форс-мажор), інтернет-магазин https://bellamozzarella.com.ua/ несе
        відповідальність у межах сум, отриманих від клієнтів як оплата за
        товари або послуги.
    </p>
    <p>
        7.3. Інтернет-магазин https://bellamozzarella.com.ua/ не несе і ні
        за яких обставин не може нести ніякої матеріальної відповідальності,
        що виходить за межі сум, отриманих від клієнта як оплата за товари
        та послуги.
    </p>
    <p>
        7.4. Інтернет-магазин https://bellamozzarella.com.ua/ залишає за
        собою право відмовити в наданні послуг або продажу товару Покупцю на
        власний розсуд.
    </p>
    <p>
        7.5. Грошові кошти, оплачені Покупцем, не повертаються в наступних
        випадках:
    </p>
    <p>
        А) відмова клієнта від отриманої послуги або товару після оплати та
        отримання від кур’єра;
    </p>
    <p>Б) за рішенням суду;</p>
    <p>В) якщо надано товар належної якості.</p>
    <p>
        7.6. Місцем продажу Товару є заклад громадського харчування
        (ресторан) Продавця.
    </p>
    <p>
    <b>8. Відповідальність перед третіми особами.</b>
    </p>
    <br />
    <p>
        8.1. Інтернет-магазин https://bellamozzarella.com.ua/ не несе ніякої
        відповідальності за використання або подальше використання товарів і
        послуг, придбаних в інтернет-магазині
        https://bellamozzarella.com.ua/та за його допомогою.
    </p>
    <p>
        8.2. Інтернет-магазин https://bellamozzarella.com.ua/ не несе
        відповідальності за вміст та якість проданих товарів за умови
        наявності сертифікатів імпортера та документів, що підтверджують
        придбання даної продукції в імпортера.
    </p>
    <br />
    <p>
        <b>9. Права та обов’язки Сторін.</b>
    </p>
    <p>
        <b>9.1. Продавець зобов’язується:</b>
    </p>
    <p>
        9.1.1. Не розголошувати будь-яку приватну інформацію Покупця і не
        надавати доступ до цієї інформації третім особам, за винятком
        випадків, передбачених чинним законодавством України.
    </p>
    <p>
        9.1.2. Надати Покупцю можливість отримання безкоштовних телефонних
        консультацій за телефонами, вказаними на сайті
        (https://bellamozzarella.com.ua/). Обсяг консультацій обмежується
        конкретними питаннями, пов’язаними з виконанням замовлення.
    </p>
    <p>
        9.1.3. Продавець залишає за собою право невиконання замовлення в
        разі виникнення форс-мажорних обставин.
    </p>
    <p>
        9.1.4. Продавець залишає за собою право змінювати даний Договір в
        односторонньому порядку до моменту його укладання.
    </p>
    <p>
        9.2. Продавець не несе відповідальності за неналежне використання
        товарів Покупцем, замовлених в інтернет-магазині.
    </p>
    <p>
        9.3. Продавець має право передати свої права та обов’язки з
        виконання Замовлень третім особам.
    </p>
    <p>
    <b>9.4. Покупець зобов’язується:</b>
    </p>
    <p>
        9.4.1. До моменту звернення в інтернет-магазин
        https://bellamozzarella.com.ua/ ознайомитися зі змістом
        Договору-оферти, умовами оплати і доставки на сайті магазину.
    </p>
    <p>
        9.5. Право власності на Замовлення, а також ризик його випадкового
        пошкодження чи втрати переходять до Покупця з моменту передачі
        Товару.
    </p>
    <br />
    <p>
        <b>10. Форс-мажорні обставини.</b>
    </p>
    <p>
        10.1. Сторони звільняються від відповідальності за невиконання чи
        неналежне виконання зобов’язань за умовами Договору на час дії
        непереборної сили. Під непереборною силою розуміються надзвичайні і
        непереборні за даних умов обставини, що перешкоджають виконанню
        своїх зобов’язань Сторонами за цим Договором. До них належать
        стихійні явища (землетруси, повені і т. ін.), обставини суспільного
        життя (воєнні дії, надзвичайні стани, найбільші страйки, епідемії,
        тощо), заборонні заходи державних органів (заборона перевезень,
        валютні обмеження, міжнародні санкції, заборони на торгівлю
        тощо).Протягом цього часу Сторони не мають взаємних претензій і
        кожна зі сторін приймає на себе свій ризик за наслідками
        форс-мажорних обставин.
    </p>
    <br />
    <p>
        <b>11. Авторські права.</b>
    </p>
    <p>
        Уся текстова інформація та графічні зображення, що розміщені на
        сайті інтернет-магазину (https://bellamozzarella.com.ua/), є
        власністю Продавця та / або його постачальників і виробників Товару.
    </p>
    <br />
    <p>
        <b>12. Інформація та її використання.</b>
    </p>
    <p>
        12.1. Покупець зобов’язаний надати точну і правдиву інформацію в
        обсязі, необхідному для надання послуг та / або купівлі товару, які
        він отримує або купує. Інформація, надана покупцем, за необхідності
        може бути опублікована у відкритих джерелах.
    </p>
    <p>
        12.2. Інтернет-магазин https://bellamozzarella.com.ua/ має право на
        власний розсуд вимагати від Покупця документального підтвердження
        автентичності наданої інформації.
    </p>
    <p>
        12.3. Надання неточної інформації або ненадання такої інформації за
        вимогою інтернет-магазину https://bellamozzarella.com.ua/ може
        слугувати приводом для призупинення надання послуг або продажу
        товару. При цьому інтернет-магазин https://bellamozzarella.com.ua/
        не несе відповідальності за будь-яку шкоду, заподіяну Покупцем, у
        випадку призупинення / невиконання послуг або непродажу товару за
        умови невиконання пункту 12.1.
    </p>
    <p>- виконання умов даного Договору;</p>
    <p>- доставки Покупцю замовленої ним продукції.</p>
    <p>
        12.5. Здійснюючи Замовлення Товару через інтернет-магазин
        https://bellamozzarella.com.ua/ Покупець дає згоду на збір та
        опрацювання такої інформації.
    </p>
    <p>
        12.6. Доступ до персональних даних Покупця мають лише особи, які
        безпосередньо залучені до виконання Замовлення.
    </p>
    <p>
        12.7. Продавець зобов’язується: дотримуватися конфіденційності
        стосовно персональних даних Покупців; не допускати спроби
        несанкціонованого використання персональних даних Покупців третіми
        особами; виключити доступ до персональних даних Покупців осіб, що не
        мають безпосереднього відношення до виконання Замовлення, окрім
        випадків, передбачених чинним законодавством України.
    </p>
`;
