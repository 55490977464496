import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import { Seo, Toggle, NutValue, Badge } from "../../components";
import { SERVER } from "../../constants/config";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { TYPE_DISHES, MOBILE_WIDTH } from "../../constants/data";

import styles from "./styles.module.scss";

const HalfView = ({ t, product, addDishToCart }) => {
  const boards = product.dishes[0].pizza.boards.map((item) => item.name);

  const activeProduct = product.dishes.filter((dish) => dish.pizza.halves);

  const [activeOne, setActiveOne] = useState(-1);
  const [activeTwo, setActiveTwo] = useState(-1);
  const [board, setBoard] = useState([boards[0]]);
  const [collected, setCollected] = useState(false);

  const { width } = useWindowDimensions();
  const mobileView = width < MOBILE_WIDTH;

  const change = (index) => {
    if (activeOne === index) {
      setActiveOne(-1);
    } else if (activeTwo === index) {
      setActiveTwo(-1);
    } else if (activeOne === -1) {
      setActiveOne(index);
    } else if (activeOne !== -1 && activeTwo === -1) {
      setActiveTwo(index);
    } else if (activeOne !== -1 && activeTwo !== -1) {
      setActiveOne(index);
      setActiveTwo(-1);
    }
  };

  let boardPrice = 0;
  activeProduct[0].pizza.boards.forEach((item) => {
    if (item.name === board[0]) {
      boardPrice = item.price ? item.price : 0;
    }
  });

  const totalPrice =
    (activeOne !== -1 ? activeProduct[activeOne].price / 2 : 0) +
    (activeTwo !== -1 ? activeProduct[activeTwo].price / 2 : 0) +
    boardPrice +
    1;

  const HalfButton = (props) => {
    return (
      <div className={styles.footerWrapper}>
        {(activeProduct[activeOne] || activeProduct[activeTwo]) && (
          <div
            className={
              props.data === "half" ? styles.joinTotal : styles.joinTotalHide
            }
          >
            <div className={styles.joinDish}>
              <span>
                {activeProduct[activeOne] ? activeProduct[activeOne].name : ""}
              </span>
              <span className={styles.joinPrice}>
                {activeProduct[activeOne]
                  ? `${activeProduct[activeOne].price}${t("currency")}`
                  : ""}
              </span>
            </div>
            <div className={styles.joinDish}>
              <span>
                {activeProduct[activeTwo] ? activeProduct[activeTwo].name : ""}
              </span>
              <span className={styles.joinPrice}>
                {activeProduct[activeTwo]
                  ? `${activeProduct[activeTwo].price}${t("currency")}`
                  : ""}
              </span>
            </div>
          </div>
        )}
        <button
          className={
            activeOne !== -1 && activeTwo !== -1
              ? styles.addToCart
              : styles.addToCartDisabled
          }
          type="button"
          disabled={activeOne === -1 && activeTwo === -1 ? true : false}
          onClick={() => {
            if (mobileView && !collected) {
              collect();
            } else {
              onPressBuyHalf();
            }
          }}
        >
          {`${t(`pages.${props.data}.${props.chooce}`)} ${
            activeProduct[activeOne] && activeProduct[activeTwo]
              ? totalPrice + t("currency")
              : ""
          } `}
        </button>
      </div>
    );
  };

  const nutHalf = activeProduct.filter((item, index) => {
    if (activeOne == index || activeTwo == index) {
      return item;
    }
  });

  const collect = () => {
    setCollected(true);
  };

  const onPressBuyHalf = () => {
    const left = activeProduct[activeOne];
    const right = activeProduct[activeTwo];
    const purchaseDish = {
      type: TYPE_DISHES.half,
      name: `${left.name} + ${right.name}`,
      count: 1,
      price: totalPrice,
      initialPrice: totalPrice,
      board: board[0],
      pizzas: [
        {
          id: left.id,
          name: left.name,
          image:
            boards[0] === board[0]
              ? SERVER + left.image[2].url
              : SERVER + left.image[4].url,

          price: left.price / 2 + boardPrice / 2,
        },
        {
          id: right.id,
          name: right.name,
          image:
            boards[0] === board[0]
              ? SERVER + right.image[3].url
              : SERVER + right.image[5].url,
          price: right.price / 2 + boardPrice / 2,
        },
      ],
      description: `${left.id}/${right.id}/${board[0]}`,
      text: t("pages.half.description"),
    };
    addDishToCart(purchaseDish);
  };

  return (
    <div className={styles.container}>
      {!mobileView || (mobileView && !collected) ? (
        <div className={styles.leftSide}>
          <Seo t={t} path="half" />
          <p className={styles.title}>
            <h2>{t("pages.half.choosePizzas")}</h2>
          </p>
          <div className={styles.dishes}>
            {activeProduct.map((item, index) => {
              let partPrice = item.price / 2;
              partPrice = partPrice.toFixed();
              return (
                <div
                  key={index}
                  className={
                    activeOne === index || activeTwo === index
                      ? styles.dishActive
                      : styles.dish
                  }
                  onClick={() => change(index)}
                >
                  <div className={styles.wrapperImage}>
                    <img
                      className={styles.images}
                      src={SERVER + item.image[0].url}
                    />
                    {activeOne === index && activeOne !== -1 && (
                      <div className={styles.transparencyLeft} />
                    )}
                    {activeTwo === index && activeTwo !== -1 && (
                      <div className={styles.transparencyRight} />
                    )}
                    <Badge data={item.badge} />
                  </div>
                  <p className={styles.name}>{item.name}</p>
                  <p className={styles.price}>
                    {partPrice}
                    {t("currency")}
                  </p>
                </div>
              );
            })}
          </div>
          {mobileView &&
            (activeOne !== -1 && activeTwo !== -1 ? (
              <HalfButton data={"half"} chooce={"addToCart"} />
            ) : (
              <HalfButton data={"half"} chooce={"title"} />
            ))}
        </div>
      ) : null}

      {!mobileView || collected ? (
        <div className={styles.rightSide}>
          <div className={styles.rigthContant}>
            <div className={styles.preview}>
              <div className={styles.leftWrapper}>
                {activeOne !== -1 && (
                  <img
                    className={styles.images}
                    src={
                      boards[0] !== board[0]
                        ? SERVER + activeProduct[activeOne].image[1].url
                        : SERVER + activeProduct[activeOne].image[0].url
                    }
                  />
                )}
              </div>
              <div className={styles.rightWrapper}>
                {activeTwo !== -1 && (
                  <img
                    className={styles.images}
                    src={
                      boards[0] !== board[0]
                        ? SERVER + activeProduct[activeTwo].image[1].url
                        : SERVER + activeProduct[activeTwo].image[0].url
                    }
                  />
                )}
              </div>
            </div>
            <p className={styles.titleTop}>
              <span>
                {activeProduct[activeOne]?.name}
                {activeProduct[activeOne] && activeProduct[activeTwo] && " + "}
                {activeProduct[activeTwo]?.name}
              </span>
              {activeProduct[activeOne] || activeProduct[activeTwo] ? (
                <NutValue product={nutHalf} />
              ) : null}
            </p>
            <p className={styles.description}>
              {activeProduct[activeOne] || activeProduct[activeTwo]
                ? t("pages.half.weight")
                : null}
              {activeProduct[activeOne] &&
                activeProduct[activeOne].calorie.weight / 2}
              {activeProduct[activeOne] && t("pages.half.gram")}
              {activeProduct[activeOne] && activeProduct[activeTwo] && " + "}
              {activeProduct[activeTwo] &&
                activeProduct[activeTwo].calorie.weight / 2}
              {activeProduct[activeTwo] && t("pages.half.gram")}
            </p>
            <div
              className={
                activeOne === -1 && activeTwo === -1
                  ? styles.toggleContainer
                  : styles.toggleContainerActive
              }
            >
              <Toggle
                items={boards}
                activeItem={board}
                setActiveItem={setBoard}
                activeIndex={0}
              />
            </div>
            <div className={styles.cards}>
              {activeOne !== -1 ? (
                <div className={styles.card}>
                  <div className={styles.imageContainer}>
                    <img
                      className={styles.image}
                      src={
                        boards[0] !== board[0]
                          ? SERVER + activeProduct[activeOne].image[1].url
                          : SERVER + activeProduct[activeOne].image[0].url
                      }
                    />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.name}>
                      {activeProduct[activeOne].name}
                    </div>
                    <div className={styles.description}>
                      {activeProduct[activeOne].short_description}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.blank}>
                  {t("pages.half.choose")}
                  <br />
                  {t("pages.half.left")}
                </div>
              )}
              {activeTwo !== -1 ? (
                <div className={styles.card}>
                  <div className={styles.imageContainer}>
                    <img
                      className={styles.image}
                      src={
                        boards[0] !== board[0]
                          ? SERVER + activeProduct[activeTwo].image[1].url
                          : SERVER + activeProduct[activeTwo].image[0].url
                      }
                    />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.name}>
                      {activeProduct[activeTwo].name}
                    </div>
                    <div className={styles.description}>
                      {activeProduct[activeTwo].short_description}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.blank}>
                  {t("pages.half.choose")}
                  <br />
                  {t("pages.half.right")}
                </div>
              )}
            </div>
          </div>
          <div className={styles.addToCartDish}>
            <HalfButton data={"dish"} chooce={"addToCart"} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(HalfView);
