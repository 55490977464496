import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { getBonus } from "../../store/redux/iiko";
import { createOrder, cleanOrder } from "../../store/redux/orders";
import CartView from "./CartView";

const Cart = ({ visibleCart, isVisibleCart }) => {
  const orders = useSelector((state) => state.orders, shallowEqual);
  const settings = useSelector((state) => state.settings.data, shallowEqual);
  const iiko = useSelector((state) => state.iiko.data, shallowEqual);
  const categories = useSelector(
    (state) => state.categories.data,
    shallowEqual
  );
  const dispatch = useDispatch();

  const getIikoBonus = (phone) => {
    dispatch(getBonus(phone));
  };

  const createNewOrder = (orderFormatFields) => {
    dispatch(createOrder({ orderFormatFields }));
  };

  const cleanOldOrder = () => {
    dispatch(cleanOrder({}));
  };

  return (
    <CartView
      data={orders}
      settings={settings}
      iiko={iiko}
      categories={categories}
      getIikoBonus={getIikoBonus}
      createNewOrder={createNewOrder}
      visibleCart={visibleCart}
      isVisibleCart={isVisibleCart}
      cleanOrder={cleanOldOrder}
    />
  );
};

export default Cart;
