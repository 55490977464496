import _ from "lodash";

export const sortByPosition = (data, cart) => {
  let dataValid = data;
  cart
    ? (dataValid = dataValid
        .slice()
        .sort((a, b) => a.position_cart - b.position_cart))
    : (dataValid = dataValid.slice().sort((a, b) => a.position - b.position));
  return dataValid;
};

export const getAllMenu = (categories) => {
  const allMenu = categories.map((category) => {
    return category.dishes.map((dish) => dish);
  });
  return [].concat(...allMenu);
};

export const sumQuantity = (data) => {
  const countArray = data.map((item) => item.count);
  return _.sum(countArray);
};

export const sumAmount = (data) => {
  const priceArray = data.map((item) => item.price * item.count);
  return _.sum(priceArray);
};
