import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '../../api';

export const getCategory = createAsyncThunk(
  'category/getCategory',
  async id => {
    const response = await api().categories.getCategory(id);
    return response.data;
  },
);

export const categorySlice = createSlice({
  name: 'category',
  initialState: {data: {}, loading: false, error: null},
  reducers: {},
  extraReducers: {
    [getCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCategory.pending]: state => {
      state.loading = true;
    },
    [getCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default categorySlice.reducer;
