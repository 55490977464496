import React, { useState } from "react";

import styles from "./styles.module.scss";
import { withTranslation } from "react-i18next";
import { GrCircleInformation } from "react-icons/gr";
import { useLocation } from "react-router-dom";

const NutValue = ({ t, product }) => {
  const [calorieOpen, setCalorieOpen] = useState(false);
  const localDish = useLocation().search.includes('combo');
  const localHalf = useLocation().search.includes('half');

  return (
    <div>
      <GrCircleInformation
        className={styles.infoIcon}
        onClick={() => setCalorieOpen(!calorieOpen)}
      />

      {calorieOpen && product && (
        <div className={styles.nutritional}>
          {product.map((item, index) => {
            const { calorie } = item;
            return (
              <div key={index}>
                <p className={localDish || localHalf ? styles.title : styles.titleHide}>{item.name}</p>
                <p>
                  <span className={styles.nutriValue}>{t("components.nutritional.nutriValue")}</span>
                </p>
                <p>
                  <span>{t("components.nutritional.energy")}</span>
                  <span>
                    {calorie.energy} {t("components.nutritional.calories")}
                  </span>
                </p>
                <p>
                  <span>{t("components.nutritional.squirrels")}</span>
                  <span>
                    {calorie.protein} {t("components.nutritional.gram")}
                  </span>
                </p>
                <p>
                  <span>{t("components.nutritional.fats")}</span>
                  <span>
                    {calorie.fats} {t("components.nutritional.gram")}
                  </span>
                </p>
                <p>
                  <span>{t("components.nutritional.carbohydrates")}</span>
                  <span>
                    {calorie.carbohydrates} {t("components.nutritional.gram")}
                  </span>
                </p>
                <p>
                  <span>{t("components.nutritional.weight")}</span>{" "}
                  <span>
                    {calorie.weight} {t("components.nutritional.gram")}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(NutValue);
