import packageJson from "../../package.json";

export const SERVER = process.env.REACT_APP_SERVER;
export const SERVER_ORDER_KEPEER = process.env.REACT_APP_SERVER_ORDER_KEPEER;

export const IIKO_SERVER = process.env.REACT_APP_IIKO_SERVER;
export const IIKO_ORGANIZATION_ID = process.env.REACT_APP_IIKO_ORGANIZATION_ID;
export const IIKO_USER_ID = process.env.REACT_APP_IIKO_USER_ID;
export const IIKO_USER_SECRET = process.env.REACT_APP_IIKO_USER_SECRET;

export const LIQPAY_SERVER = process.env.REACT_APP_LIQPAY_SERVER;
export const LIQPAY_PUBLIC_KEY = process.env.REACT_APP_LIQPAY_PUBLIC_KEY;
export const LIQPAY_PRIVATE_KEY = process.env.REACT_APP_LIQPAY_PRIVATE_KEY;
export const LIQPAY_RESULT_URL = process.env.REACT_APP_LIQPAY_RESULT_URL;
export const LIQPAY_SERVER_URL = process.env.REACT_APP_LIQPAY_SERVER_URL;

export const EMAILJS_HOST = process.env.REACT_APP_EMAILJS_HOST;
export const EMAILJS_PATH = process.env.REACT_APP_EMAILJS_PATH;
export const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
export const EMAILJS_PRIVATE_KEY = process.env.REACT_APP_EMAILJS_PRIVATE_KEY;
export const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
export const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

export const CLIENT_EMAIL_1 = process.env.REACT_APP_CLIENT_EMAIL_1;

export const MERCHANT = process.env.REACT_APP_MERCHANT;

export const ENV = process.env.NODE_ENV;
export const VERSION = packageJson.version;
