import React from "react";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  sumQuantity,
  getAllMenu,
  sortByPosition,
} from "../../../../helpers/item";

import {
  curentProductPrice,
  totalProductsCost,
} from "../../../../helpers/calculator";

import { TYPE_DISHES } from "../../../../constants/data";
import {
  addOrder,
  updateOrder,
  deleteOrder,
} from "../../../../store/redux/orders";

import styles from "./styles.module.scss";
import { SERVER } from "../../../../constants/config";

const List = ({ t, orders, categories, delivery, settings }) => {
  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    variableWidth: true,
    swipeToSlide: true,
  };
  const dispatch = useDispatch();

  const renderAdditive = () => {
    const cartDishes = getAllMenu(categories).filter((item) => item.cart);
    const onPressBuyDish = (dish) => {
      const purchaseDish = {
        id: dish.id,
        type: TYPE_DISHES.dish,
        name: dish.name,
        image: SERVER + dish.image[0].url,
        extraCost: 0,
        initialPrice: dish.price,
        board: null,
        additives: "-",
        price: dish.discount ? dish.discount : dish.price,
        count: 1,
        deleteIngredients: "-",
        description: dish.pizza.active
          ? `${dish.id} / ${
              dish.pizza.active ? dish.pizza.boards[0].name : ""
            } / - /${dish.pizza.active ? `+ ` : null}`
          : dish.short_description,
      };
      dispatch(addOrder(purchaseDish));
    };
    return (
      <div>
        <div className={styles.additiveTitle}>
          {t("pages.cart.components.list.additiveTitle")}
        </div>
        <Slider {...settingsSlider}>
          {sortByPosition(cartDishes).map((item, index) => {
            return (
              <div key={index} onClick={() => onPressBuyDish(item)}>
                <article className={styles.additiveItem}>
                  <img
                    className={styles.additiveImage}
                    src={SERVER + item.image[0].url}
                  />
                  <div className={styles.additiveBlock}>
                    <h4 className={styles.additiveName}>{item.name}</h4>
                    <span>
                      {item.price} {t("currency")}
                    </span>
                  </div>
                </article>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  const renderImage = (dish) => {
    if (dish.type === TYPE_DISHES.half) {
      return dish.pizzas.map((item, index) => {
        return (
          <img key={index} className={styles.imageHalves} src={item.image} />
        );
      });
    } else if (dish.type === TYPE_DISHES.combo) {
      return <img className={styles.imageCombo} src={dish.image} />;
    } else {
      return <img className={styles.imageDish} src={dish.image} />;
    }
  };

  const renderDescription = (dish) => {
    if (dish.type === TYPE_DISHES.half) {
      return (
        <div className={styles.description}>
          {t("pages.cart.components.list.board")}
          {dish.board}
        </div>
      );
    } else if (dish.type === TYPE_DISHES.combo) {
      return <div className={styles.description}>{dish.description}</div>;
    } else if (dish.type === TYPE_DISHES.pizza) {
      return (
        <div>
          <div className={styles.description}>
            {dish.board && t("pages.cart.components.list.board")}
            {dish.board}
          </div>
          {dish.additives.length > 1 && (
            <div className={styles.description}>
              {dish.additives && dish.additives}
            </div>
          )}
          {dish.deleteIngredients.length > 1 && (
            <div className={styles.description}>
              {dish.deleteIngredients && dish.deleteIngredients}
            </div>
          )}
        </div>
      );
    } else {
      return <div className={styles.description}>{dish.description}</div>;
    }
  };

  const renderDish = (dish, index) => {
    return (
      <div className={styles.dish} key={index}>
        <div className={styles.dishInfoWrapper}>
          {renderImage(dish)}
          <div className={styles.dishInfo}>
            <h3 className={styles.dishName}>{dish.name}</h3>
            {renderDescription(dish)}
          </div>
        </div>
        <div className={styles.subtotal}>
          <div>{`${
            dish.count * curentProductPrice(dish, delivery, orders, settings)
          } ${t("currency")}`}</div>
          <div className={styles.quantity}>
            <button
              onClick={() =>
                dispatch(
                  dish.count === 1
                    ? deleteOrder(dish)
                    : updateOrder({ order: dish, sign: "dec" })
                )
              }
            >
              -
            </button>
            <div className={styles.quantityInput}>{dish.count}</div>
            <button
              onClick={() =>
                dispatch(updateOrder({ order: dish, sign: "inc" }))
              }
            >
              +
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderOrders = () => {
    return orders.map((order, index) => {
      return renderDish(order, index);
    });
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.subTitle}>{`${sumQuantity(orders)} ${t(
        "pages.cart.components.list.subTitle"
      )} ${totalProductsCost(orders, delivery, settings)} ${t(
        "currency"
      )}`}</h3>
      <RenderOrders />
      {renderAdditive()}
    </div>
  );
};

export default List;
