import React from "react";
import { TYPE_DELIVERY } from "../../../../constants/data";
import { MdRestaurant, MdNoMeals } from "react-icons/md";

import styles from "./styles.module.scss";
import { IMaskInput } from "react-imask";

const Delivery = ({
  t,
  delivery,
  setDelivery,
  phone1,
  phone2,
  setPhone2,
  name,
  setName,
  address,
  setAddress,
  setAddressPickUp,
  time,
  setTime,
  comment,
  setComment,
  isCutlery,
  setIsCutlery,
}) => {
  const phoneMask = "+{00} (000) 00 00 000";
  const deliveryPlaces = [
    {
      label: t("pages.cart.components.delivery.address1"),
      value: t("pages.cart.components.delivery.address1"),
    },
    {
      label: t("pages.cart.components.delivery.address2"),
      value: t("pages.cart.components.delivery.address2"),
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {t("pages.cart.components.delivery.title")}
      </h3>
      <ul className={styles.choise}>
        <li
          className={delivery === TYPE_DELIVERY[0] && styles.current}
          onClick={() => setDelivery(TYPE_DELIVERY[0])}
        >
          {t("pages.cart.components.delivery.home")}
        </li>
        <li
          className={delivery === TYPE_DELIVERY[1] && styles.current}
          onClick={() => setDelivery(TYPE_DELIVERY[1])}
        >
          {t("pages.cart.components.delivery.cafe")}
        </li>
      </ul>
      <input
        type="text"
        value={name}
        placeholder={t("pages.cart.components.delivery.name")}
        onChange={(e) => setName(e.target.value)}
        className={styles.inputName}
      />
      {phone1.length !== 19 && (
        <IMaskInput
          className={styles.inputPhone}
          mask={phoneMask}
          value={phone2}
          onAccept={(value) => setPhone2(value)}
          placeholder={t("pages.cart.components.delivery.phone")}
        />
      )}
      {delivery === TYPE_DELIVERY[0] ? (
        <input
          type="text"
          value={address}
          placeholder={t("pages.cart.components.delivery.address")}
          onChange={(e) => setAddress(e.target.value)}
          className={styles.inputAddress}
        />
      ) : (
        <select
          className={styles.select}
          onChange={(e) => setAddressPickUp(e.target.value)}
        >
          {deliveryPlaces.map((item, index) => {
            return (
              <option className={styles.option} key={index} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      )}

      <ul className={styles.timeDelivery}>
        <li>
          <label>
            <input
              type="radio"
              value="now"
              checked={time === "now"}
              onChange={(e) => setTime(e.target.value)}
            />
            <span>{t("pages.cart.components.delivery.now")}</span>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              value="time"
              checked={time !== "now"}
              onChange={() => setTime("")}
            />
            <span>{t("pages.cart.components.delivery.onTime")} </span>
          </label>
        </li>
        {time !== "now" && (
          <input
            type="text"
            value={time}
            placeholder={t("pages.cart.components.delivery.time")}
            className={styles.inputTime}
            onChange={(e) => setTime(e.target.value)}
          />
        )}
      </ul>
      <textarea
        className={styles.comment}
        maxLength={90}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder={t("pages.cart.components.delivery.comment")}
      />
      <div className={styles.cutlery}>
        <span className={styles.nomeals}>
          <span>
            <MdNoMeals className={styles.icon} />
          </span>
          <label className={styles.label}>
            {t("pages.cart.components.delivery.nomeals")}
          </label>
        </span>
        <span className={styles.meals}>
          <span>
            <MdRestaurant className={styles.icon} />
          </span>
          <label className={styles.label}>
            {t("pages.cart.components.delivery.meals")}
          </label>
        </span>

        <div className={styles.quantity}>
          <button
            onClick={() => setIsCutlery(isCutlery >= 1 ? isCutlery - 1 : 0)}
          >
            -
          </button>
          <div className={styles.quantityInput}>{isCutlery}</div>
          <button onClick={() => setIsCutlery(isCutlery + 1)}>+</button>
        </div>
      </div>
      <label className={styles.required}>
        {t("pages.cart.components.delivery.required")}
      </label>
    </div>
  );
};

export default Delivery;
