import React from "react";
import { withTranslation } from "react-i18next";

import { Seo } from "../../components";
import Navigation from "../../components/Navigation";
import { currentLang } from "../../helpers/language";

import styles from "./styles.module.scss";

const DeliveryView = ({ t, i18n }) => {
  const renderText = () => {
    const lang = currentLang();
    const languages = Object.keys(i18n.options.resources);
    if (lang === languages[0]) {
      return (
        <div className={styles.container}>
          <br />
          <h1>Food delivery in Kharkov.</h1>
          <br />

          <p>
            Food delivery in Kharkov is carried out when ordering from 399 UAH.
            Free delivery throughout the city. When ordering from 2000 UAH, an
            advance payment of 50% is required. When picking up from the street
            A 10% discount applies to Cumska 73 and Petro Grihorenko Ave. 18/1.
            <p>
              Promotions and discounts are not cumulative. 5% of the amount of
              the order made through the application is credited as bonuses, it
              does not apply to promotional items.
            </p>
            <br />
            Delivery operators are ready to take your orders from 9:00 to 21:30.
            The time of receiving and issuing the order may change due to
            technical reasons.
          </p>
          <br />

          <p>
            Delivery in Kharkov from Bella Mozzarella is tasty, fast and
            inexpensive. Bella Mozzarella is a place where all your preferences
            and wishes will be taken into account. The food is prepared only by
            professional craftsmen according to special recipes and only from
            fresh products, so when ordering food delivery in Kharkov, you can
            see for yourself.
          </p>
          <br />

          <p>
            Our menu has a huge selection of dishes for every, even the most
            sophisticated taste. We have a huge selection of pizzas, burgers,
            salads, first courses, side dishes, sauces, and various drinks. We
            have more than 30 types of pizzas in our assortment, which can
            easily replace a full meal, several varieties of first courses, more
            than 5 types of the most popular and healthy salads, and most
            importantly, all this is always fresh and at reasonable prices.
          </p>
          <br />

          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1PNRAfLYy4A9zl9Gf5jjBlH23ZcU4qgEz&ehbc=2E312F"
            width="640"
            height="480"
          ></iframe>

          <h2>Order food.</h2>
          <br />

          <p>
            You can order food in Kharkov anywhere in the city, at any place
            convenient for you. You can order food delivery both to the office
            for yourself and for colleagues, and most importantly, you can order
            it at home for the festive table, and while on vacation, even being
            in nature, and you don’t have to stand at the stove, breaking out of
            the last strength after a long working week, because 24-hour food
            delivery, as well as our professional chefs will cook your favorite
            food for you, and you can enjoy a good rest and your favorite
            dishes. In addition, the taste of our dishes will be appreciated not
            only by you, but also by your family, friends and colleagues.
          </p>
          <br />

          <p>
            Delivery works very quickly, which means you can enjoy your favorite
            dishes in a fairly short period from the moment you place an order
            with us. Ordering in Kharkov pizza from Bella Mozzarella is a nice
            addition to any situation!
          </p>
          <br />

          <h3>Order food in Kharkov.</h3>
          <br />

          <p>
            You can order food in Kharkov at Bella Mozzarella in any way
            convenient for you, either by placing your order on the website or
            by contacting our operator by phone. Having ordered food delivery in
            Kharkov, you can pay for your order in a way convenient for you: in
            cash, through the LiqPay system, placing an order on the site, or
            using a terminal, indicating this when placing an order through the
            site, or informing the operator in advance by phone.
          </p>
          <br />

          <p>
            You don&quot;t have to pay us extra for this. Enjoy your favorite
            dishes of Italian cuisine in any weather, at any time of the year!
          </p>
          <br />

          <p>
            We have also expanded our delivery area. Now you can order delivery
            to some points in the region, they are marked in yellow on the map.
            Delivery conditions: order from 999 UAH. , delivery cost is 79 UAH.
          </p>
          <br />

          <p>
            Please note that on holidays, the cost of the minimum order for free
            delivery and the waiting time may increase.
          </p>
          <br />

          <p>Check with the operator for all delivery details.</p>
          <br />

          <p>So, are you ready to order?</p>
          <br />

          <p>Delivery phones:</p>
          <br />

          <ul>
            <li>+38 (093) 4321000</li>
            <li>+38 (098) 4321000</li>
            <li>+38 (099) 4321000</li>
          </ul>
          <br />
        </div>
      );
    } else if (lang === languages[1]) {
      return (
        <div className={styles.container}>
          <br />
          <h1>Доставка їжі Харків.</h1>
          <br />

          <p>
            Доставка їжі в Харкові здійснюється при замовленні від 399 грн.
            Безкоштовна доставка по всьому місту. При замовленні від 2000 грн –
            необхідна передоплата 50%.
          </p>

          <p>
            При самовивезенні з вул. Cумської 73 та пр. Петра Григоренко 18/1
            діє знижка 10%.
          </p>
          <br />

          <p>
            Акції та знижки не сумуються. Бонусами нараховується 5% від суми
            замовлення, зроблене через додаток, не розповсюджується на акційні
            позиції.
          </p>
          <br />

          <p>
            Оператори доставки готові приймати ваші замовлення з 9:00 до 21:30.
            Час прийому та видачі замовлення може змінюватися з технічних
            причин.
          </p>
          <br />

          <p>
            Доставка їжі Харків від Bella Mozzarella – це смачно, швидко та
            недорого. Bella Mozzarella – це місце, де врахують усі Ваші
            уподобання та побажання. Їжа приготована тільки професійними
            майстрами за спеціальними рецептами та тільки зі свіжих продуктів,
            тому замовивши доставку їжі в Харкові, ви зможете в цьому
            переконатися.
          </p>
          <br />

          <p>
            В нашому меню є величезний вибір страв на будь-який, навіть
            найвишуканіший смак. У нас є величезний вибір піци, бургерів,
            салатів, перших страв, гарнірів, соусів та різних напоїв.
          </p>
          <br />

          <p>
            У нас в асортименті більше 30 видів піци, що цілком може замінити
            повноцінний прийом їжі, кілька різновидів перших страв, більше 5
            видів найпопулярніших і найкорисніших салатів, а найголовніше все це
            завжди свіже та за прийнятними цінами.
          </p>
          <br />

          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1PNRAfLYy4A9zl9Gf5jjBlH23ZcU4qgEz&ehbc=2E312F"
            width="640"
            height="480"
          ></iframe>

          <h2>Замовити їжу.</h2>
          <br />

          <p>
            Замовити їжу в Харкові ви можете в будь-яку точку міста, будь-яке
            місце, зручне для Вас. Замовити доставку їжі ви можете як в офіс для
            себе, так і для колег, а найголовніше, Ви можете замовити її і
            додому до святкового столу, і перебуваючи на відпочинку, навіть на
            природі, і Вам не доведеться стояти біля плити, вибиваючись з
            останніх сил після довгого трудового робочого тижня, тому що
            цілодобова доставка їжі, а також наші професійні кухарі приготують
            вашу улюблену їжу замість вас, і ви зможете насолодитися повноцінним
            відпочинком та улюбленими стравами.
          </p>

          <p>
            До того ж, смакові якості наших страв зможете оцінити не тільки Ви,
            а й ваша сім&quot;я, друзі та колеги.
          </p>
          <br />

          <p>
            Доставка працює дуже швидко, а значить поласувати улюбленими
            стравами Ви зможете у досить короткий період від моменту зробленого
            Вами замовлення. Замовити піцу Харків з Bella Mozzarella - приємне
            доповнення до будь-якої ситуації!
          </p>
          <br />

          <h3>Замовити їжу Харків.</h3>
          <br />

          <p>
            Замовити їжу в Харкові в Bella Mozzarella Ви можете у будь-який
            зручний для вас спосіб, як оформивши своє замовлення на сайті, так і
            зв&quot;язавшись з нашим оператором по телефону. Замовивши доставку
            їжі в Харкові Ви можете оплатити своє замовлення у зручний для вас
            спосіб: готівкою, через систему LiqPay, оформивши замовлення на
            сайті, або за допомогою терміналу, вказавши це при оформленні
            замовлення через сайт, або заздалегідь повідомивши про це оператора
            по телефону.
          </p>
          <br />

          <p>
            Насолоджуйтесь улюбленими стравами італійської кухні у будь-яку
            погоду, у будь-яку пору року!
          </p>
          <br />

          <p>
            Також ми розширили нашу Зону доставки. Тепер Ви можете замовити
            доставку в деякі точки області, вони позначені на карті жовтим
            кольором. Умови доставки: замовлення від 999 грн. , вартість
            доставки складає 79 грн.
          </p>
          <br />

          <p>
            Звертеємо Вашу увагу, що у святкові дні вартість мінімального заказу
            для безкоштовної доставки, та час очікування можуть збільшуватись.
          </p>
          <br />

          <p>Усі деталі доставки уточнюйте у оператора.</p>
          <br />

          <p>Ну що готові зробити замовлення?</p>
          <br />

          <p>Телефони доставки:</p>
          <br />

          <ul>
            <li>+38 (093) 4321000</li>
            <li>+38 (098) 4321000</li>
            <li>+38 (099) 4321000</li>
          </ul>
          <br />
        </div>
      );
    } else if (lang === languages[2]) {
      return (
        <div className={styles.container}>
          <br />
          <h1>Доставка еды Харьков.</h1>
          <br />

          <p>
            Бесплатная доставка еды в Харькове, осуществляется при заказе от 399
            грн. Доставка по всему городу. При заказе от 2000 грн — необходима
            предоплата 50%. При самовывозе с ул. Cумской 73 и пр. Петра
            Григоренка 18/1 действует скидка 10%.
            <p>
              Акции и скидки не скучают. Бонусами начисляется 5% от суммы
              заказа, сделанное через приложение, не распространяется на
              акционные позиции.
            </p>
            <br />
            Операторы доставки готовы принимать ваши заказы с 9:00 до 21:30.
            Время приема и выдачи заказа может изменяться по техническим
            причинам.
          </p>
          <br />

          <p>
            Доставка еды Харьков от Bella Mozzarella – это вкусно, быстро и
            недорого. Bella Mozzarella – это место, где учтут все Ваши
            предпочтения и пожелания. Еда приготовлена только профессиональными
            мастерами по специальным рецептам и только из свежих продуктов,
            поэтому заказав доставку еды в Харькове, Вы сами сможете в этом
            убедиться.
          </p>
          <br />

          <p>
            У нас в меню есть огромный выбор блюд на любой, даже самый
            изысканный вкус. У нас есть огромный выбор пицц, бургеров, салатов,
            первых блюд, гарниров, соусов, и разных напитков. У нас в
            ассортименте более 30 видов пицц, что вполне может заменить
            полноценный прием пищи, несколько разновидностей первых блюд, более
            5 видов самых популярных и полезных салатов, а самое главное всё это
            всегда свежее и по приемлемым ценам.
          </p>
          <br />

          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1PNRAfLYy4A9zl9Gf5jjBlH23ZcU4qgEz&ehbc=2E312F"
            width="640"
            height="480"
          ></iframe>

          <h2>Заказать еду.</h2>
          <br />

          <p>
            Заказать еду в Харькове Вы можете в любую точку города, в любое
            место, удобное для Вас. Заказать доставку еды Вы можете как в офис
            для себя, так и для коллег, а самое главное, Вы можете заказать ее и
            домой к праздничному столу, и находясь на отдыхе, будучи даже на
            природе, и Вам не придется стоять у плиты, выбиваясь из последних
            сил после долгой трудовой рабочей недели, потому что доставка еды, а
            также наши профессиональные повара приготовят Вашу любимую еду
            вместо вас, и вы сможете насладиться полноценным отдыхом и Вашими
            любимыми блюдами. К тому же вкусовые качества наших блюд сможете
            оценить не только Вы, но и Ваша семья, друзья и коллеги.
          </p>
          <br />

          <p>
            Доставка работает очень быстро, а значит полакомиться любимыми
            блюдами Вы сможете в довольно короткий период от момента сделанного
            Вами у нас заказа. Заказать пиццу Харьков из Bella Mozzarella —
            приятное дополнение к любой ситуации!{" "}
          </p>
          <br />

          <h3>Заказать еду Харьков.</h3>
          <br />

          <p>
            Заказать еду в Харькове в Bella Mozzarella Вы можете любым удобным
            для Вас способом, как оформив свой заказ на сайте, так и связавшись
            с нашим оператором по телефону. Заказав доставку еды в Харькове Вы
            можете оплатить свой заказ удобным для Вас способом: наличными,
            через систему LiqPay, оформив заказ на сайте, либо при помощи
            терминала, указав это при оформлении заказа через сайт, либо заранее
            сообщив об этом оператору по телефону.
          </p>
          <br />

          <p>
            Наслаждайтесь любимыми блюдами итальянской кухни в любую погоду, в
            любое время года!
          </p>
          <br />

          <p>
            Так же мы расширили нашу Зону доставки. Теперь Вы можете заказать
            доставку в некоторые точки области, они отмечены на карте жёлтым
            цветом. Условия доставки: заказ от 999 грн. , стоимость доставки
            составляет 79 грн.
          </p>
          <br />

          <p>
            Обращаем Ваше внимание, что в праздничные дни стоимость минимального
            заказа для бесплатной доставки, а так же время ожидания заказа могут
            увеличиваться.
          </p>
          <br />

          <p>Все детали по доставке уточняйте у оператора.</p>
          <br />

          <p>Ну что, готовы сделать заказ?</p>
          <br />

          <p>Телефоны доставки:</p>
          <br />

          <ul>
            <li>+38 (093) 4321000</li>
            <li>+38 (098) 4321000</li>
            <li>+38 (099) 4321000</li>
          </ul>
          <br />
        </div>
      );
    }
  };

  return (
    <>
      <Seo t={t} path="delivery" />
      <Navigation cart />
      <Navigation />
      <div className={styles.container}>{renderText()}</div>
    </>
  );
};

export default withTranslation()(DeliveryView);
