import i18next from "i18next";
import resources from "./languages";
import { DEFAULT_LANG } from "../constants/data";

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: DEFAULT_LANG,
  resources,
});

export default i18next;
