import API from './base/api';
import {SERVER, SERVER_ORDER_KEPEER} from '../constants/config';
import {
  EMAILJS_HOST,
  EMAILJS_PATH,
  EMAILJS_PUBLIC_KEY,
  EMAILJS_PRIVATE_KEY,
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
  CLIENT_EMAIL_1,
} from '../constants/config';

class OrdersApi extends API {
  async createOrder(data) {
    return this.r({
      method: 'POST',
      url: `${SERVER}/orders`,
      data,
    });
  }

  async sendEmail(orderFormatFields) {
    const data = {
      service_id: EMAILJS_SERVICE_ID,
      template_id: EMAILJS_TEMPLATE_ID,
      user_id: EMAILJS_PUBLIC_KEY,
      template_params: {
        reply_to: CLIENT_EMAIL_1,
        ...orderFormatFields,
      },
      accessToken: EMAILJS_PRIVATE_KEY,
    };

    return this.r({
      method: 'POST',
      url: `${EMAILJS_HOST}${EMAILJS_PATH}`,
      data,
    });
  }

  async createOrderWithOnlinePayment(data) {
    return this.r({
      method: 'POST',
      url: `${SERVER_ORDER_KEPEER}/order`,
      data,
    });
  }
}

export default function ordersApi(request) {
  return new OrdersApi(request);
}
