import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '../../api';

export const getArticle = createAsyncThunk('article/getArticle', async id => {
  const response = await api().articles.getArticle(id);
  return response.data;
});

export const articleSlice = createSlice({
  name: 'article',
  initialState: {data: {}, loading: false, error: null},
  reducers: {},
  extraReducers: {
    [getArticle.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getArticle.pending]: state => {
      state.loading = true;
    },
    [getArticle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default articleSlice.reducer;
