import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '../../api';

export const getCombo = createAsyncThunk('combo/getCombo', async id => {
  const response = await api().combos.getCombo(id);
  return response.data;
});

export const comboSlice = createSlice({
  name: 'combo',
  initialState: {data: {}, loading: false, error: null},
  reducers: {},
  extraReducers: {
    [getCombo.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCombo.pending]: state => {
      state.loading = true;
    },
    [getCombo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default comboSlice.reducer;
