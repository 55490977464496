import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '../../api';

export const getBonus = createAsyncThunk('iiko/getBonus', async phone => {
  const iiko = await api().iiko.authIiko(phone.replace(' ', ''));
  const response = await api().iiko.getUser(iiko.data, phone);
  return response.data.walletBalances[0].balance;
});

export const iikoSlice = createSlice({
  name: 'iiko',
  initialState: {data: null, loading: false, error: null},
  reducers: {},
  extraReducers: {
    [getBonus.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getBonus.pending]: state => {
      state.loading = true;
    },
    [getBonus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default iikoSlice.reducer;
