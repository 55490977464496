import React from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { HALF, DISH } from "../../constants/routers";

import styles from "./styles.module.scss";

const Badge = ({ t, data }) => {
  const { pathname } = useLocation();
  const localHalf = pathname.includes(HALF.slice(6));
  const localDish = pathname.includes(DISH.slice(6, -3));

  if (data?.new) {
    return (
      <div className={styles.container}>
        <div
          className={
            localHalf
              ? styles.badgeHalf
              : localDish
              ? styles.badgeDish
              : styles.badge
          }
        >
          {data.text}
          {t("components.badge.new")}
        </div>
      </div>
    );
  } else if (data?.enable_text) {
    return (
      <div className={styles.container}>
        <div
          className={
            localHalf
              ? styles.badgeHalf
              : localDish
              ? styles.badgeDish
              : styles.badge
          }
        >
          {data.text}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withTranslation()(Badge);

// className={
//   localDish ? styles.newDish : localHalf ? styles.newHalf : styles.new
// }
