export const MAIN = '/*';
export const DELIVERY = ':lang/delivery';
export const TERMS = ':lang/terms';
export const DISH = ':lang/dish/:id';
export const COMBO = ':lang/combo/:id';
export const HALF = ':lang/half';
export const CATEGORY = ':lang/category/:name';
export const DISH_IN_CATEGORY = '/:lang/category/:name/dish/:id';
export const COMBO_IN_CATEGORY = '/:lang/category/:name/combo/:id';
export const ARTICLE = ':lang/article/:name/:id';
