import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { HiOutlineX } from "react-icons/hi";

import { GOOGLE_PLAY, APP_STORE } from "../../constants/data";
import assets from "../../assets";
import styles from "./styles.module.scss";

const KEY = "@apps";

const Apps = ({ t }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const localVisible = localStorage.getItem(KEY);
    if (localVisible !== "1") {
      setTimeout(() => setVisible(true), 5000);
    }
  }, []);

  const closeApps = () => {
    setVisible(false);
    localStorage.setItem(KEY, "1");
  };

  return (
    <div className={visible ? styles.container : styles.containerNone}>
      <div className={styles.header}>
        <HiOutlineX className={styles.delete} onClick={closeApps} />
        <div>
          <p className={styles.title}>{t("components.apps.title")}</p>
          <p className={styles.subTitle}>{t("components.apps.text")}</p>
        </div>
      </div>

      <div className={styles.apps}>
        <a href={APP_STORE}>
          <img
            className={styles.image}
            src={assets.IMAGES.APP_STORE}
            alt="App store"
          />
        </a>

        <a href={GOOGLE_PLAY}>
          <img
            className={styles.image}
            src={assets.IMAGES.GOOGLE_PLAY}
            alt="Google play"
          />
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(Apps);
