import API from './base/api';
import {SERVER} from '../constants/config';
import {currentLang} from '../helpers/language';

class CombosApi extends API {
  async getCombos() {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/combos?_locale=${lang}`,
    });
  }

  async getCombo(id) {
    const lang = currentLang();
    return this.r({
      method: 'GET',
      url: `${SERVER}/combos/${id}?_locale=${lang}`,
    });
  }
}

export default function combosApi(request) {
  return new CombosApi(request);
}
