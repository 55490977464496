import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";

import { Language } from "../index";
import { DELIVERY, TERMS } from "../../constants/routers";
import {
  FACEBOOK,
  INSTAGRAM,
  TEAM,
  YOUTUBE,
  GOOGLE_PLAY,
  APP_STORE,
} from "../../constants/data";
import { currentLang } from "../../helpers/language";
import assets from "../../assets";

import styles from "./styles.module.scss";

const Footer = ({ t }) => {
  const lang = currentLang();
  return (
    <div className={styles.container}>
      <div className={styles.callUs}>
        <a href="tel:+38 099 43 21 000">
          <IoIosCall className={styles.callUsIcon} />
        </a>
      </div>
      <Link to={TEAM} className={styles.team}>
        <div className={styles.teamBlock}>
          <img
            className={styles.teamIconPeople}
            src={assets.ICONS.PEOPLE}
            alt="People"
          />
          <h4 className={styles.teamTitle}>
            {t("components.footer.joinTheTeam")}
          </h4>
        </div>

        <button
          className={styles.teamButton}
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/1tFU_K93JU2i9FFkRYOvX99yV8X6HJeNVtWTrAaFoms8",
              "_blank"
            );
          }}
        >
          {t("components.footer.joinTheTeamButton")}
        </button>

        <BsArrowRight className={styles.teamIconArrow} />
      </Link>
      <div className={styles.footer}>
        <div className={styles.desktopRow}>
          <section className={styles.block}>
            <p className={styles.title}>{t("components.footer.title1")}</p>
            <Link className={styles.link} to={`${lang}/${DELIVERY.slice(6)}`}>
              <p className={styles.text}>{t("components.footer.f1text3")}</p>
            </Link>
            <Link className={styles.link} to={`${lang}/${TERMS.slice(6)}`}>
              <p className={styles.text}>{t("components.footer.f3text2")}</p>
            </Link>
          </section>
          <section className={styles.block}>
            <p className={styles.title}>{t("components.footer.title2")}</p>
            <a
              className={styles.phone}
              href={`tel:${t("components.footer.f2text1")}`}
            >
              <p className={styles.text}>{t("components.footer.f2text1")}</p>
            </a>
            <a
              className={styles.phone}
              href={`tel:${t("components.footer.f2text2")}`}
            >
              <p className={styles.text}>{t("components.footer.f2text2")}</p>
            </a>
            <a
              className={styles.phone}
              href={`tel:${t("components.footer.f2text3")}`}
            >
              <p className={styles.text}>{t("components.footer.f2text3")}</p>
            </a>
            <a
              className={styles.phone}
              href={`mailto:${t("components.footer.f2text4")}`}
            >
              <p className={styles.text}>{t("components.footer.f2text4")}</p>
            </a>
          </section>
          <section className={styles.block}>
            <p className={styles.title}>{t("components.footer.title3")}</p>
            <div className="address">
              <div>
                <div className={styles.text}>
                  {t("components.footer.f3text3")}
                </div>
                <p className={styles.text}></p>
              </div>
            </div>
            <p className={styles.text}>{t("components.footer.f3text4")}</p>
            <p className={styles.text}>{t("components.footer.f3text5")}</p>
            <br />
            <p className={styles.text}>{t("components.footer.f3text6")}</p>
            <p className={styles.text}>{t("components.footer.f3text7")}</p>
          </section>
          <div className={styles.desktopMobApps}>
            <div className={styles.apps}>
              <a href="/">
                <img
                  className={styles.appsImage}
                  src={assets.IMAGES.GOOGLE_PLAY}
                  alt="Google play"
                />
              </a>
              <a href="/">
                <img
                  className={styles.appsImage}
                  src={assets.IMAGES.APP_STORE}
                  alt="App store"
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.strip} />
        <div className={styles.row}>
          <div className={styles.social}>
            <a className={styles.socialIconBackground} href={FACEBOOK}>
              <img
                className={styles.socialIconFacebook}
                src={assets.ICONS.FACEBOOK}
                alt="Facebook"
              />
            </a>
            <a className={styles.socialIconBackground} href={INSTAGRAM}>
              <img
                className={styles.socialIconInstagram}
                src={assets.ICONS.INSTAGRAM}
                alt="Instagram"
              />
            </a>
            <a className={styles.socialIconBackground} href={YOUTUBE}>
              <img
                className={styles.socialIconYoutube}
                src={assets.ICONS.YOUTUBE}
                alt="YouTube"
              />
            </a>
          </div>

          <div className={styles.mobileApps}>
            <div className={styles.apps}>
              <a href={GOOGLE_PLAY}>
                <img
                  className={styles.appsImage}
                  src={assets.IMAGES.GOOGLE_PLAY}
                  alt="Google play"
                />
              </a>
              <a href={APP_STORE}>
                <img
                  className={styles.appsImage}
                  src={assets.IMAGES.APP_STORE}
                  alt="App store"
                />
              </a>
            </div>
            <Language />
          </div>
          <div className={styles.copyright}>
            <p className={styles.copyrightText1}>
              {t("components.footer.copyright1")}
            </p>
            <p className={styles.copyrightText2}>
              {t("components.footer.copyright2")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
